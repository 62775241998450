import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import Tab from "../../components/Tab/Tab"
import ChangeCredentials from "../../components/ChangeCredentials/ChangeCredentials"
import ChangeEmail from "../../components/ChangeEmail/ChangeEmail"
import ChangeLanguage from "../../components/ChangeLanguage/ChangeLanguage"
import './SettingsPage.css'
import {ReactComponent as SettingsIcon} from '../../images/settings-icon.svg'
import ChangePhoto from "../../components/ChangePhoto/ChangePhoto";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import ChangeContacts from "../../components/ChangeContacts/ChangeContacts";
import Popup from "../../components/Popup/Popup";
import {ReactComponent as Emoji14Icon} from "../../images/emoji-14-icon.svg"
import emoji14src from "../../images/emoji-14-icon.png"
import DeleteAccount from "../../components/DeleteAccount/DeleteAccount";
import {setActiveTab, setContentPopup, updateDoctorData} from "../../store/actions";
import {settingsPage as translations} from "../../translations";
import _ from "../../helpers/_";
import getFlow from "../../helpers/getFlow";


export default () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const contentPopup = useSelector(state => state.settings.contentPopup)
    const newEmail = useSelector(state => state.settings.temp.changeEmail.email)
    const texts = translations[language]
    const country = useSelector(state => state.app.doctorData.country)
    let contentPopupContainer = null

    useEffect(() => {
        dispatch(updateDoctorData)
        if (window.location.hash) {
            const id = window.location.hash.replace(/#/g, '')
            dispatch(setActiveTab(id))
        }
        return () => dispatch(setActiveTab(null))
    }, [])

    switch (contentPopup) {
        case 'remove_account_email_send':
            contentPopupContainer = (
                <Popup type="content">
                    <img src={emoji14src} alt="Emoji"/>
                    <div className="popup-title">{texts.emailSent}</div>
                    <div className="popup-text">{texts.deleteAccountText}</div>
                    <button className="tab-button tab-button--save" onClick={() => dispatch(setContentPopup(''))} >
                        {texts.backToSettings}
                    </button>
                </Popup>
            )
            break
        case 'update_email_send':
            contentPopupContainer = (
                <Popup type="content">
                    <Emoji14Icon />
                    <div className="popup-title">{texts.confirmNewEmailTitle}</div>
                    <div className="popup-text">
                        {texts.confirmNewEmailText1}
                        <div className="popup-email">{newEmail}</div>
                        {texts.confirmNewEmailText2}
                    </div>
                    <button className="tab-button tab-button--save update_email_send" onClick={()=>dispatch(setContentPopup(''))} >
                        {texts.backToSettings}
                    </button>
                </Popup>
            )
            break
    }
    const mainContent = (
        <>
            <header className="settings-header">
                <div className="settings-icon-wrapper">
                    <SettingsIcon />
                </div>
                <div className="settings-header-title">
                    {translations[language].settings}
                </div>
            </header>
            <div className="settings-content-wrapper">
                <Tab id="changeCredentials" title={translations[language].changeCredentials}>
                    <ChangeCredentials />
                </Tab>
                <Tab id="changeContactDetails" title={translations[language].changeContactDetails}>
                    <ChangeContacts />
                </Tab>
                {_(getFlow(country) === 'us',
                <Tab id="changePassword" title={translations[language].changePassword}>
                    <ChangePassword />
                </Tab>)}
                {_(getFlow(country) === 'us',
                <Tab id="changeEmail" title={translations[language].changeEmail}>
                    <ChangeEmail />
                </Tab>)}
                <Tab id="myPhoto" title={translations[language].myPhoto}>
                    <ChangePhoto />
                </Tab>
                {/*<Tab id="bankingInformation" title={translations[language].bankingInformation}>
                <div>Test text</div>
            </Tab>*/}
                <Tab id="changeLanguage" title={translations[language].changeLanguage}>
                    <ChangeLanguage />
                </Tab>
                <Tab id="deleteAccount" title={translations[language].deleteAccount}>
                    <DeleteAccount />
                </Tab>
            </div>
        </>
    )

    const content = contentPopupContainer ? contentPopupContainer : mainContent

    return content
}