import GoogleLogin from "react-google-login";
import {ReactComponent as GoogleIcon} from "../../images/google-icon.svg";
import AppleLogin from "react-apple-login";
import {ENV, ENV_SUBDOMAIN, PROD} from "../../constants/appConstants";
import {ReactComponent as AppleIcon} from "../../images/apple-icon.svg";
import React from "react";
import {appleLogin, googleLogin, googleSignUp} from "../../store/actions";
import {useDispatch} from "react-redux";
import getLanguage from "../../helpers/getLanguage";
import {initialScreenWrapper as translations} from "../../translations";

export default props => {
    const dispatch = useDispatch()
    const language = getLanguage()
    const texts = translations[language]


    /*
    * Handlers
    * */
    const responseGoogle = response => {
        if (response && response.tokenId) {
            if (props.type === 'sign-in') {
                dispatch(googleLogin(response.tokenId, language))
            } else {
                dispatch(googleSignUp(response.tokenId, language))
            }
        }
    }
    const responseApple = response => {
        if (response && response.authorization && response.authorization.code) {
            dispatch(appleLogin(response.authorization.code))
        }
    }


    return <>
        <GoogleLogin
            clientId="224019031598-i52ukoht8qkp3p7066r6j9qtb9naccgs.apps.googleusercontent.com"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            render={renderProps => (
                <button onClick={renderProps.onClick} className="continue-google"><GoogleIcon/>{texts.continueGoogle}
                </button>
            )}
        />
        <AppleLogin
            clientId="com.comebackmobility.doctor-web"
            redirectURI={`https://${ENV === PROD ? 'app.' : ENV_SUBDOMAIN}comebackmobility.com`}
            responseMode="form_post"
            scope="email name"
            usePopup={true}
            callback={responseApple}
            render={renderProps => (
                <button onClick={renderProps.onClick} className="continue-apple"><AppleIcon/>{texts.continueApple}
                </button>
            )}
        />
    </>
};