import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {
    updateFieldValidation,
    setActiveTab,
    setSettingData,
    setSettingTabData,
    showFieldsErrors, setContentPopup, setNewEmail, updateField
} from "../../store/actions"
import waveEffect from "../../helpers/waveEffect";
import ValidateField from "../ValidateField/ValidateField";
import {changeEmail as translations} from "../../translations";


export default () => {
    const tabKey = 'changeEmail'
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const token = useSelector(state => state.app.token)
    const doctorId = useSelector(state => state.app.doctorId)
    const email = useSelector(state => state.app.doctorData.email)
    const tabData = useSelector(state => state.settings.temp[tabKey])
    const validation = useSelector(state => state.validation.validation)
    const texts = translations[language]

    useEffect(() => dispatch(setSettingTabData(tabKey, { email })), [])

    const fieldChangeHandler = fieldKey => e => {
        const enableError = validation.hasOwnProperty(fieldKey) && !validation[fieldKey].showError
        if (enableError) {
            dispatch(updateFieldValidation({id: fieldKey, showError: true}))
        }
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const value = e.target.value
        dispatch(setSettingData(tabKey, fieldKey, value.toLowerCase()))
        setTimeout(function () {
            e.target.setSelectionRange(caretStart, caretEnd);
        })
    }
    const saveButtonHandler = e => {
        waveEffect(e)
        const tabInvalid = Object.values(validation).find(item => item.valid === false)
        if (tabInvalid) {
            dispatch(showFieldsErrors())
        } else {
            dispatch(setNewEmail(token, doctorId, language, tabData.email))
            dispatch(setContentPopup('update_email_send'))
            dispatch(setActiveTab(''))
        }
    }

     const emailValidationRules = [
         [tabData.email === email, texts.currentEmail],
         [!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(tabData.email), texts.incorrectEmail],
     ]
    const emailShowError = (validation && validation.email && validation.email.showError) || false

    if (tabData.loaded === false) return <div />
    return (
        <>
            <div className="tab-section">
                <label htmlFor="email">
                    <div className="tab-label-text">
                        {translations[language].newEmail}
                    </div>
                    <ValidateField id="email" showError={emailShowError} validation={emailValidationRules} >
                        <input id="email" type="text" value={tabData.email} className="tab-input"
                               onChange={fieldChangeHandler('email')} autoComplete="off" />
                    </ValidateField>
                </label>
            </div>
            <div className="tab-section">
                <button className="tab-button tab-button--save" onClick={saveButtonHandler}>
                    {translations[language].save}
                </button>
            </div>
        </>
    )
}