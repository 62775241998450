import React from "react";

const total = {
    en: {
        back: 'Back ',
        next: 'Next',
    },
    ru: {
        back: 'Назад',
        next: 'Далее',
    },
    uk: {
        back: 'Назад',
        next: 'Далі',
    }
}

/*
* Pages
* */
export const signInPage = {
    en: {
        incorrectEmail: 'Incorrect format of email address',
        password: 'Password',
        enterPassword: 'Enter password',
        forgotPassword: 'Forgot your password?',
        signIn: 'Sign in',
        incorrectData: 'You\'ve entered incorrect email or password',
        passLengthHint: '8 to 25 characters',
        passRuleHint: 'At least 1 number and 1 letter',
        newUser: 'New user?',
        registerNow: 'Register now',
        or: 'or',
    },
    ru: {
        incorrectEmail: 'Неправильный формат email адреса',
        password: 'Пароль',
        enterPassword: 'Введите пароль',
        forgotPassword: 'Забыли пароль?',
        signIn: 'Войти',
        incorrectData: 'Вы ввели неправильный email или пароль',
        passLengthHint: 'От 8 до 25 символов',
        passRuleHint: 'Минимум 1 цифра и 1 буква',
        newUser: 'Новый пользователь?',
        registerNow: 'Зарегистрируйтесь',
        or: 'или',
    },
    uk: {
        incorrectEmail: 'Неправильний формат email адреси',
        password: 'Пароль',
        enterPassword: 'Введіть пароль',
        forgotPassword: 'Забули пароль?',
        signIn: 'Увійти',
        incorrectData: 'Ви ввели неправильний email або пароль',
        passLengthHint: 'Від 8 до 25 символів',
        passRuleHint: 'Мінімум 1 цифра і 1 буква',
        newUser: 'Новий користувач?',
        registerNow: 'Зареєструйтесь',
        or: 'або',
    },
}
export const signUpPage = {
    en: {
        incorrectEmail: 'Incorrect format of email address',
        password: 'Password',
        enterPassword: 'Enter password',
        signUp: 'Register now',
        incorrectData: 'You\'ve entered incorrect email or password',
        passLengthHint: '8 to 25 characters',
        passRuleHint: 'At least 1 number and 1 letter',
        haveAccount: 'Already have an account?',
        signIn: 'Sign in',
        byRegistering: 'By registering, I agree to the',
        and: 'and',
        terms: 'Terms of Service',
        privacy: 'Privacy Policy',
        or: 'or',
    },
    ru: {
        incorrectEmail: 'Неправильный формат email адреса',
        password: 'Пароль',
        enterPassword: 'Введите пароль',
        signUp: 'Зарегистрироваться',
        incorrectData: 'Вы ввели неправильный email или пароль',
        passLengthHint: 'От 8 до 25 символов',
        passRuleHint: 'Минимум 1 цифра и 1 буква',
        haveAccount: 'Уже есть аккаунт?',
        signIn: 'Выполните вход',
        byRegistering: 'Регистрируясь, я принимаю ',
        and: 'и',
        terms: 'Условия использования',
        privacy: 'Политику конфиденциальности',
        or: 'или',
    },
    uk: {
        incorrectEmail: 'Неправильний формат email адреси',
        password: 'Пароль',
        enterPassword: 'Введіть пароль',
        signUp: 'Зареєструватися',
        incorrectData: 'Ви ввели неправильний email або пароль',
        passLengthHint: 'Від 8 до 25 символів',
        passRuleHint: 'Мінімум 1 цифра і 1 буква',
        haveAccount: 'Вже є аккаунт?',
        signIn: 'Увійдіть',
        byRegistering: 'Реєструючись, я приймаю',
        and: 'та',
        terms: 'Умови використання',
        privacy: 'Політику конфіденційності',
        or: 'або',
    },
}
export const creationAccount = {
    en: {
        information: 'Your information',
        credentials: 'Credentials',
        contacts: 'Contacts',
        receivingPayments: 'Receiving payments',
        stepInfo: (currentStep, stepsLength) => `Step ${currentStep + 1} of ${stepsLength}`,
    },
    ru: {
        information: 'Знакомство',
        credentials: 'Информация о работе',
        contacts: 'Контакты',
        receivingPayments: 'Получение выплат',
        stepInfo: (currentStep, stepsLength) => `Шаг ${currentStep + 1} из ${stepsLength}`,
    },
    uk:  {
        information: 'Знайомство',
        credentials: 'Інформація про роботу',
        contacts: 'Контакти',
        receivingPayments: 'Отримання виплат',
        stepInfo: (currentStep, stepsLength) => `Крок ${currentStep + 1} з ${stepsLength}`,
    }
}
export const myPatientsPage = {
    en: {
        myPatients: 'My patients',
        totalPatients: 'Total patients',
        addPatient: 'Add patient',
        trauma: 'Trauma',
        surgery: 'Surgery',
        payment: 'Awaiting start',
        start_at: 'Awaiting start',
        in_progress: 'In Progress',
        completed: 'Rehabilitated',
        canceled: 'Canceled',
        patientsListEmptyText: 'Please add your first patient',
        noPatients: 'No patients at this status',

        dayOutOff: 'day out of',
        currentLoad: 'Current weight bearing',
        setWeightCompliance: 'Compliance with the set weight bearing',
    },
    ru: {
        myPatients: 'Мои пациенты',
        totalPatients: 'Всего пациентов',
        addPatient: 'Добавить пациента',
        trauma: 'Травма',
        surgery: 'Операция',
        payment: 'Ожидаем начала',
        start_at: 'Ожидаем начала',
        in_progress: 'Реабилитируется',
        completed: 'Восстановлен',
        canceled: 'Отменено',
        patientsListEmptyText: 'Добавьте своего первого пациента',
        noPatients: 'Нет пациентов в этом статусе',

        dayOutOff: 'день из',
        currentLoad: 'Текущая нагрузка',
        setWeightCompliance: 'Соблюдение заданной нагрузки',
    },
    uk: {
        myPatients: 'Мої пацієнти',
        totalPatients: 'Всього пацієнтів',
        addPatient: 'Додати пацієнта',
        trauma: 'Травма',
        surgery: 'Операція',
        payment: 'Чекаємо початку',
        start_at: 'Чекаємо початку',
        in_progress: 'Реабілітується',
        completed: 'Відновлений',
        canceled: 'Скасовано',
        patientsListEmptyText: 'Додайте свого першого пацієнта',
        noPatients: 'Немає пацієнтів в цьому статусі',

        dayOutOff: 'день з',
        currentLoad: 'Поточне навантаження',
        setWeightCompliance: 'Дотримання заданого навантаження',
    }
}
export const patientPage = {
    en: {
        myPatients: 'My patients',
        from: 'from',
        to: 'to',
        week: 'week',
        month: 'Month',
        yearShort: 'y',
        allPeriod: 'All period',
    },
    ru: {
        myPatients: 'Мои пациенты',
        from: 'с',
        to: 'до',
        week: 'неделя',
        month: 'Месяц',
        yearShort: 'г',
        allPeriod: 'Весь период',
    },
    uk: {
        myPatients: 'Мої пацієнти',
        from: 'з',
        to: 'до',
        week: 'Тиждень',
        month: 'Місяць',
        yearShort: 'р',
        allPeriod: 'Весь період',
    }
}
export const consultationsPage = {
    en: {
        consultations: 'Consultations',
        totalConsultations: 'Total consultations',
        uah: 'UAH',
        totalRevenueText: 'Total revenue from consulting:',
        paymentTime: 'Payment time',
        fison: '"FISON." LTD consultations',
        cardAccount: 'Card / Account',
        amount: 'Amount, UAH',
        receipt: 'Receipt',
        canceled: 'Canceled',
        patientsListEmptyText: 'Please add your first patient',
        weExpect: <>{'We expect income from'} <br /> {'your first consultation'}</>,
        deletedPatient: 'Deleted Patient',
    },
    ru: {
        consultations: 'Консультации',
        totalConsultations: 'Всего консультаций',
        uah: 'грн',
        totalRevenueText: 'Общий доход от консультаций:',
        paymentTime: 'Время оплаты',
        fison: 'Консультация ООО "ФИСОН."',
        cardAccount: 'Карта / Счёт',
        amount: 'Сумма, грн',
        receipt: 'Квитанция',
        canceled: 'Отменено',
        patientsListEmptyText: 'Добавьте своего первого пациента',
        weExpect: <>{'Ожидаем доход от первой'} <br /> {'Вашей консультации'}</>,
        deletedPatient: 'далённый пациент',
    },
    uk: {
        consultations: 'Консультації',
        totalConsultations: 'Всього консультацій',
        uah: 'грн',
        totalRevenueText: 'Загальний дохід від консультацій:',
        paymentTime: 'Час оплати',
        fison: 'Консультація ТОВ "ФІСОН"',
        cardAccount: 'Карта / Рахунок',
        amount: 'Сума, грн',
        receipt: 'Квитанція',
        canceled: 'Скасовано',
        patientsListEmptyText: 'Додайте свого першого пацієнта',
        weExpect: <>{'Очікуємо дохід від першої'} <br /> {'Вашої консультації'}</>,
        deletedPatient: 'Видалений пацієнт',
    }
}
export const notificationsPage = {
    en: {
        notifications: 'Notifications',
        surgery: 'Surgery',
        notificationRemoved: 'Notification removed',
        noNotifications: 'You have no notifications yet',
        totalNotifications: 'Total notifications',
        complains: 'Patient complaint',
        program_completed: 'Patient\'s rehabilitation is over',
        do_not_follow: 'Patient does not follow the recommendations',
        read: 'Read',
        unread: 'Unread',

        patientComplaint: 'Patient complaint',
        worries: 'Worries:',
        temperature: 'Temperature:',
        descriptionTitle: 'Patient comment:',
        close: 'Close',
    },
    ru: {
        notifications: 'Уведомления',
        surgery: 'Операция',
        notificationRemoved: 'Уведомление удалено',
        noNotifications: 'У Вас пока нет уведомлений',
        totalNotifications: 'Всего уведомлений',
        complains: 'Жалоба пациента',
        program_completed: 'Завершил программу',
        do_not_follow: 'Не соблюдает рекомендации',
        read: 'Прочитанные',
        unread: 'Непрочитанные',

        patientComplaint: 'Жалоба пациента',
        worries: 'Что беспокоит:',
        temperature: 'Температура:',
        descriptionTitle: 'Комментарий пациента:',
        close: 'Закрыть',
    },
    uk: {
        notifications: 'Повідомлення',
        surgery: 'Операція',
        notificationRemoved: 'Повідомлення видалено',
        noNotifications: 'У Вас поки немає повідомлень',
        totalNotifications: 'Всього повідомлень',
        complains: 'Скарга пацієнта',
        program_completed: 'Пацієнт закінчив реабілітацію',
        do_not_follow: 'Пацієнт не дотримується рекомендацій',
        read: 'Прочитані',
        unread: 'Непрочитані',

        patientComplaint: 'Скарга пацієнта',
        worries: 'Що турбує:',
        temperature: 'Температура:',
        descriptionTitle: 'Коментар пацієнта:',
        close: 'Закрити',
    },
}
export const supportPage = {
    en: {
        support: 'Support',
        needHelp: 'Need help?',
        weAreOnline1: 'We are online on weekdays from 9:00 to 18:00',
        weAreOnline2: ' Kiev time (GMT + 2)',
        textMe: 'Text me in ',
    },
    ru: {
        support: 'Служба поддержки',
        needHelp: 'Нужна помощь?',
        weAreOnline1: 'Мы на связи в рабочие дни с 9:00 до 18:00',
        weAreOnline2: ' по Киевскому времени (GMT+2)',
        textMe: 'Написать в ',
    },
    uk: {
        support: 'Служба підтримки',
        needHelp: 'Потрібна допомога?',
        weAreOnline1: 'Ми на зв\'язку в робочі дні з 9:00 до 18:00',
        weAreOnline2: ' за Київським часом (GMT + 2)',
        textMe: 'Написати в ',
    }
}
export const settingsPage = {
    en: {
        changeCredentials: 'Change сredentials',
        settings: 'Settings',
        changeContactDetails: 'Change contact details',
        changePassword: 'Change Password',
        changeEmail: 'Change email address',
        myPhoto: 'My photo',
        bankingInformation: 'Banking information',
        changeLanguage: 'Change language',
        deleteAccount: 'Delete my account',
        emailSent: 'Email sent',
        deleteAccountText: 'Please click on the link at the email to delete your account permanently',
        backToSettings: 'Back to settings',
        confirmNewEmailTitle: 'Confirm your new email address',
        confirmNewEmailText1: 'Thanks! The confirmation message was sent to your new email address',
        confirmNewEmailText2: 'Please check your inbox and follow the instructions to confirm',
    },
    ru: {
        changeCredentials: 'Изменить информацию о работе',
        settings: 'Настройки',
        changeContactDetails: 'Изменить контактные данные',
        changePassword: 'Изменить пароль',
        changeEmail: 'Изменить email',
        myPhoto: 'Мое фото',
        bankingInformation: 'Платежная информация',
        changeLanguage: 'Язык интерфейса',
        deleteAccount: 'Удаление аккаунта',
        emailSent: 'Email отправлен',
        deleteAccountText: 'Пожалуйста, перейдите по ссылке в письме, чтобы удалить свою учетную запись навсегда',
        backToSettings: 'Вернуться к настройкам',
        confirmNewEmailTitle: 'Подтвердите ваш новый email-адрес',
        confirmNewEmailText1: 'Спасибо! Сообщение с подтверждением отправлено на новый адрес электронной почты',
        confirmNewEmailText2: 'Пожалуйста, проверьте почту и следуйте инструкциям в письме',
    },
    uk: {
        changeCredentials: 'Змінити інформацію про роботу',
        settings: 'Налаштування',
        changeContactDetails: 'Змінити контактні дані',
        changePassword: 'Редагувати пароль',
        changeEmail: 'Змінити email',
        myPhoto: 'Моє фото',
        bankingInformation: 'Інформація про оплату',
        changeLanguage: 'Зміна мови',
        deleteAccount: 'Видалення акаунту',
        emailSent: 'Email відправлений',
        deleteAccountText: 'Будь ласка, перейдіть за посиланням у листі, щоб видалити свій обліковий запис назавжди',
        backToSettings: 'Повернутися до налаштувань',
        confirmNewEmailTitle: 'Підтвердіть ваш новий email-адрес',
        confirmNewEmailText1: 'Дякуємо! Повідомлення з підтвердженням відправлено на нову адресу електронної пошти',
        confirmNewEmailText2: 'Будь ласка, перевірте пошту і дотримуйтесь інструкцій в листі',
    }
}

/*
* Components
* */
export const changeContacts = {
    en: {
        save: 'Save',
        preferContact: 'I prefer you to contact me in',
        changesSaved: 'Changes saved',
        newPhone: 'New phone number',
        phoneNumber: 'Phone number',
        enterValidPhone: 'Please enter a valid phone number',
    },
    ru: {
        save: 'Сохранить',
        preferContact: 'Мне удобно общаться в',
        changesSaved: 'Изменения сохранены',
        newPhone: 'Новый номер телефона',
        phoneNumber: 'Номер телефона',
        enterValidPhone: 'Пожалуйста, введите действующий телефонный номер',
    },
    uk: {
        save: 'Зберегти',
        preferContact: 'Мені зручніше спілкуватись у',
        changesSaved: 'Зміни збережені',
        newPhone: 'Новий номер телефону',
        phoneNumber: 'Номер телефону',
        enterValidPhone: 'Будь ласка, введіть діючий телефонний номер',
    },
}
export const changeCredentials = {
    en: {
        specialization: 'Specialization',
        specializationOptions: {
            'Physiotherapist': 'Physiotherapist',
            'Orthopedist - traumatologist': 'Orthopedist - traumatologist',
            'Rehabilitologist': 'Rehabilitologist',
            'Surgeon': 'Surgeon',
            'Arthrologist': 'Arthrologist',
            'Chiropractor': 'Chiropractor',
            'Family physician': 'Family physician',
            'Neurologist': 'Neurologist',
            'Endocrinologist': 'Endocrinologist',
        },
        specializationOtherErrorText: 'You should enter from 3 to 50 symbols',
        position: 'Position',
        positionOptions: {
            'Physician': 'Physician',
            'Head of the department': 'Head of the department',
            'Head physician': 'Head physician',
        },
        positionOtherErrorText: 'You should enter from 3 to 50 symbols',
        workExperience: 'Work experience',
        workExperienceErrorText: 'It should be more than 0 and less than 100',
        years: 'years',
        city: 'City',
        cityPlaceholder: 'Enter сity',
        workplace: 'Workplace',
        workplacePlaceholder: 'Enter name',
        save: 'Save',
        deleteWorkplace: 'Delete place of work',
        deleteWorkplaceNotificationText: 'Place of work removed',
        addWorkPlaceText: name => `Dear ${name}, please list all the facilities where are you working`,
        addWorkPlaceButtonText: 'Add one more',
        changesSaved: 'Changes saved',
        country: 'Ukraine',
        selectPlaceholder: 'Select from the list',
    },
    ru: {
        specialization: 'Специализация',
        specializationOptions: {
            'Ортопед - травматолог': 'Ортопед - травматолог',
            'Реабилитолог (FRM)': 'Реабилитолог (FRM)',
            'Хирург': 'Хирург',
            'Артролог': 'Артролог',
            'Терапевт': 'Терапевт',
            'Врач семейной медицины': 'Врач семейной медицины',
            'Невропатолог': 'Невропатолог',
            'Эндокринолог': 'Эндокринолог',
        },
        specializationOtherErrorText: 'Введите от 3 до 50 символов',
        position: 'Должность',
        positionOptions: {
            'Ординатор': 'Ординатор',
            'Врач': 'Врач',
            'Заведующий': 'Заведующий',
            'Глав врач': 'Глав врач',
            'Врач частной практики': 'Врач частной практики',
        },
        positionOtherErrorText: 'Введите от 3 до 50 символов',
        workExperience: 'Стаж работы',
        workExperienceErrorText: 'Должно быть больше 0 и меньше 100',
        years: 'лет',
        city: 'Город',
        cityPlaceholder: 'Введите город',
        workplace: 'Место работы',
        workplacePlaceholder: 'Введите название',
        save: 'Сохранить',
        deleteWorkplace: 'Удалить место работы',
        deleteWorkplaceNotificationText: 'Место работы удалено',
        addWorkPlaceText: name => `${name}, пожалуйста, укажите список всех учреждений, в которых Вы работаете`,
        addWorkPlaceButtonText: 'Добавить еще',
        changesSaved: 'Изменения сохранены',
        country: 'Украина',
        selectPlaceholder: 'Выберите из списка',
    },
    uk: {
        specialization: 'Спеціалізація',
        specializationOptions: {
            'Ортопед - травматолог': 'Ортопед - травматолог',
            'Реабилитолог (FRM)': 'Реабилитолог (FRM)',
            'Хірург': 'Хірург',
            'Артролог': 'Артролог',
            'Терапевт': 'Терапевт',
            'Лікар сімейної медицини': 'Лікар сімейної медицини',
            'Невропатолог': 'Невропатолог',
            'Ендокринолог': 'Ендокринолог',
        },
        specializationOtherErrorText: 'Ви повинні ввести від 3 до 50 символів',
        position: 'Посада',
        positionOptions: {
            'Ординатор': 'Ординатор',
            'Лікар': 'Лікар',
            'Завідувач': 'Завідувач',
            'Глав лікар': 'Глав лікар',
            'Лікар приватної практики': 'Лікар приватної практики',
        },
        positionOtherErrorText: 'Ви повинні ввести від 3 до 50 символів',
        workExperience: 'Стаж роботи',
        workExperienceErrorText: 'Він повинен бути більше 0 і менше 100',
        years: 'років',
        city: 'Місто',
        cityPlaceholder: 'Введіть місто',
        workplace: 'Місце роботи',
        workplacePlaceholder: 'Введіть назву',
        save: 'Зберегти',
        deleteWorkplace: 'Видалити місце роботи',
        deleteWorkplaceNotificationText: 'Місце роботи видалено',
        addWorkPlaceText: name => `${name}, будь ласка, вкажіть список всіх закладів, в яких Ви працюєте`,
        addWorkPlaceButtonText: 'Додати ще',
        changesSaved: 'Зміни збережені',
        country: 'Україна',
        selectPlaceholder: 'Виберіть зі списку',
    },
}
export const changeCurrentLoad = {
    en: {
        setCurrentLoad: 'Edit the current weight bearing',
        notice: 'Editing data will affect the patient\'s program',
        save: 'Save Changes',
        cancel: 'Cancel',
    },
    ru: {
        setCurrentLoad: 'Редактирование текущей нагрузки',
        notice: 'Редактирование данных повлекут изменения в программе пациента',
        save: 'Сохранить изменения',
        cancel: 'Отменить',
    },
    uk: {
        setCurrentLoad: 'Редагування поточного навантаження',
        notice: 'Редагування даних спричинить зміни в програмі пацієнта',
        save: 'Зберегти зміни',
        cancel: 'Скасувати',
    }
}
export const changeEmail = {
    en: {
        newEmail: 'New email',
        currentEmail: 'This is your current email',
        save: 'Save',
        incorrectEmail: 'Incorrect format of email address',
    },
    ru: {
        newEmail: 'Новый email',
        currentEmail: 'Это Ваш текущий email',
        save: 'Сохранить',
        incorrectEmail: 'Неправильный формат email адреса',
    },
    uk: {
        newEmail: 'Новий email',
        currentEmail: 'Це Ваш поточний email',
        save: 'Зберегти',
        incorrectEmail: 'Неправильний формат email адреси',
    },
}
export const changeLanguage = {
    en: {
        save: 'Change language',
    },
    ru: {
        save: 'Изменить язык',
    },
    uk: {
        save: 'Змінити мову',
    },
}
export const changePassword = {
    en: {
        save: 'Change password',
        invalidPassword: 'Invalid password',
        currentPassword: 'Current password',
        newPassword: 'New password',
        confirmPassword: 'Confirm new password',
        passLengthHint: '8 to 25 characters',
        passRuleHint: 'at least 1 number and 1 letter',
        notMatchPass: 'Passwords do not match',
        matchPass: 'New password is matching your previous password',
        changesSaved: 'Password changed successfully',
        capsLock: 'Caps Lock is on',
        currentPasswordPlaceholder: 'Enter current password',
        newPasswordPlaceholder: 'Enter new password',
        confirmPasswordPlaceholder: 'Enter new password',
    },
    ru: {
        save: 'Изменить пароль',
        invalidPassword: 'Неправильный пароль',
        currentPassword: 'Текущий пароль',
        newPassword: 'Новый пароль',
        confirmPassword: 'Подтвердите пароль',
        passLengthHint: 'От 8 до 25 символов',
        passRuleHint: 'минимум 1 цифра и 1 буква',
        notMatchPass: 'Пароли не совпадают',
        matchPass: 'Новый пароль совпадает с предыдущим',
        changesSaved: 'Пароль успешно изменён',
        capsLock: 'Caps Lock включён',
        currentPasswordPlaceholder: 'Введите текущий пароль',
        newPasswordPlaceholder: 'Введите новый пароль',
        confirmPasswordPlaceholder: 'Введите новый пароль',
    },
    uk: {
        save: 'Редагувати пароль',
        invalidPassword: 'Невірний пароль',
        currentPassword: 'Поточний пароль',
        newPassword: 'Новий пароль',
        confirmPassword: 'Підтвердіть пароль',
        passLengthHint: 'Від 8 до 25 символів',
        passRuleHint: 'мінімум 1 цифра і 1 буква',
        notMatchPass: 'Паролі не збігаються',
        matchPass: 'Новий пароль відповідає попередньому',
        changesSaved: 'Пароль успішно змінений',
        capsLock: 'Caps Lock включений',
        currentPasswordPlaceholder: 'Введіть поточний пароль',
        newPasswordPlaceholder: 'Введіть новий пароль',
        confirmPasswordPlaceholder: 'Введіть новий пароль',
    },
}
export const changePhoto = {
    en: {
        uploadText: 'Upload your photo',
        removePhoto: 'Remove photo',
        photoNotExceed: 'The photo should not exceed 5 MB',
    },
    ru: {
        uploadText: 'Загрузите свое фото',
        removePhoto: 'Удалить фото',
        photoNotExceed: 'Фотография не должна превышать 5 MB',
    },
    uk: {
        uploadText: 'Завантажте своє фото',
        removePhoto: 'Видалити фото',
        photoNotExceed: 'Фотографія не повинна перевищувати 5 MB',
    },
}
export const chart = {
    en: {
        insufficientLoad: 'Insufficient weight bearing',
        recommendedLoad: 'Recommended weight bearing',
        excessiveLoad: 'Excessive weight bearing',
        load: 'Weight bearing',
    },
    ru: {
        insufficientLoad: 'Недостаточная нагрузка',
        recommendedLoad: 'Рекомендуемая нагрузка',
        excessiveLoad: 'Чрезмерная нагрузка',
        load: 'Нагрузка',
    },
    uk: {
        insufficientLoad: 'Недостатнє навантаження',
        recommendedLoad: 'Рекомендоване навантаження',
        excessiveLoad: 'Надмірне навантаження',
        load: 'Навантаження',
    }
}
export const chartDoughnut = {
    en: {
        highLoad: 'Steps with excessive weight bearing',
        normalLoad: 'Steps with recommended weight bearing',
        lowLoad: 'Steps with insufficient weight bearing',
    },
    ru: {
        highLoad: 'Шагов с чрезмерной нагрузкой',
        normalLoad: 'Шагов с рекомендуемой нагрузкой',
        lowLoad: 'Шагов с недостаточной нагрузкой',
    },
    uk: {
        highLoad: 'Кроків з надмірним навантаженням',
        normalLoad: 'Кроків з рекомендованою навантаженням',
        lowLoad: 'Кроків з недостатньою навантаженням',
    },
}
export const chartSmall = {
    en: {
        stageOver: 'Stage is over',
        currentComponent: (currentDay, currentLoad) => <>Today is the  {currentDay}th day,<br /> {currentLoad}% of the weight bearing</>
    },
    ru: {
        stageOver: 'Этап окончен',
        currentComponent: (currentDay, currentLoad) => <>Сейчас {currentDay}й день<br /> {currentLoad}% нагрузки</>
    },
    uk: {
        stageOver: 'Етап закінчено',
        currentComponent: (currentDay, currentLoad) => <>Зараз {currentDay}й день<br /> {currentLoad}% навантаження</>
    },
}
export const creationAccountAcquaintance = {
    en: {
        lastName: 'Your last name *',
        lastNamePlaceholder: 'Enter your last name',
        firstName: 'Your first name *',
        firstNamePlaceholder: 'Enter your first name',
        namesErrorText: 'You should enter from 2 to 50 symbols',
        addPhotoTitle: 'Add photo',
        addPhotoText: 'Please add your photo so patients can see you',
        back: total.en.back,
        next: total.en.next,
    },
    ru: {
        lastName: 'Ваша фамилия *',
        lastNamePlaceholder: 'Введите вашу фамилию',
        firstName: 'Ваше имя *',
        firstNamePlaceholder: 'Введите ваше имя',
        namesErrorText: 'Введите от 2 до 50 символов',
        addPhotoTitle: 'Добавьте фото',
        addPhotoText: 'Ваши пациенты будут рады видеть Вас! Пожалуйста, прикрепите Ваше фото для повышения лояльности пациентов к программе',
        back: total.ru.back,
        next: total.ru.next,
    },
    uk: {
        lastName: 'Ваше прізвище *',
        lastNamePlaceholder: 'Введіть ваше прізвище',
        firstName: 'Ваше ім\'я *',
        firstNamePlaceholder: 'Введіть ваше ім\'я',
        namesErrorText: 'Ви повинні ввести від 2 до 50 символів',
        addPhotoTitle: 'Додайте фото',
        addPhotoText: 'Ваші пацієнти будуть раді бачити Вас! Будь ласка, прикріпіть Ваше фото для підвищення лояльності пацієнтів до програми',
        back: total.uk.back,
        next: total.uk.next,
    },
}
export const creationAccountWork = {
    en: {
        specialization: 'Specialization *',
        ua_specialization: 'Specialization',
        specializationOtherErrorText: 'You should enter from 3 to 50 symbols',
        position: 'Position *',
        ua_position: 'Position',
        positionOtherErrorText: 'You should enter from 3 to 50 symbols',
        workExperience: 'Work experience *',
        workExperienceErrorText: 'It should be more than 0 and less than 100',
        years: 'years',
        city: 'City *',
        ua_city: 'City',
        cityPlaceholder: 'Enter сity',
        workplaceTitle: 'Workplace',
        workplace: 'Workplace *',
        ua_workplace: 'Workplace',
        workplacePlaceholder: 'Enter name',
        deleteWorkplace: 'Delete place of work',
        addWorkPlaceButtonText: 'Add one more',
        country: 'Country *',
        ua_country: 'Country',
        selectPlaceholder: 'Select from the list',
        back: total.en.back,
        next: total.en.next,
        submit: 'Done',
    },
    ru: {
        specialization: 'Специализация *',
        ua_specialization: 'Специализация',
        specializationOtherErrorText: 'Введите от 3 до 50 символов',
        position: 'Должность *',
        ua_position: 'Должность',
        positionOtherErrorText: 'Введите от 3 до 50 символов',
        workExperience: 'Стаж работы *',
        ua_workExperience: 'Стаж работы',
        workExperienceErrorText: 'Должно быть больше 0 и меньше 100',
        years: 'лет',
        city: 'Город *',
        ua_city: 'Город',
        cityPlaceholder: 'Введите город',
        workplaceTitle: 'Место работы',
        workplace: 'Место работы *',
        ua_workplace: 'Место работы',
        workplacePlaceholder: 'Введите название',
        deleteWorkplace: 'Удалить место работы',
        addWorkPlaceButtonText: 'Добавить еще',
        country: 'Страна *',
        ua_country: 'Страна',
        selectPlaceholder: 'Выберите из списка',
        back: total.ru.back,
        next: total.ru.next,
        submit: 'Готово',
    },
    uk: {
        specialization: 'Спеціалізація *',
        ua_specialization: 'Спеціалізація',
        specializationOtherErrorText: 'Ви повинні ввести від 3 до 50 символів',
        position: 'Посада *',
        ua_position: 'Посада',
        positionOtherErrorText: 'Ви повинні ввести від 3 до 50 символів',
        workExperience: 'Стаж роботи *',
        ua_workExperience: 'Стаж роботи',
        workExperienceErrorText: 'Він повинен бути більше 0 і менше 100',
        years: 'років',
        city: 'Місто *',
        ua_city: 'Місто',
        cityPlaceholder: 'Введіть місто',
        workplaceTitle: 'Місце роботи',
        workplace: 'Місце роботи *',
        ua_workplace: 'Місце роботи',
        workplacePlaceholder: 'Введіть назву',
        deleteWorkplace: 'Видалити місце роботи',
        addWorkPlaceButtonText: 'Додати ще',
        country: 'Країна *',
        ua_country: 'Країна',
        selectPlaceholder: 'Виберіть зі списку',
        back: total.uk.back,
        next: total.uk.next,
        submit: 'Готово',
    },
}
export const creationAccountContacts = {
    en: {
        phoneNumber: 'Phone number *',
        enterValidPhone: 'Please enter a valid phone number',
        preferContact: 'I prefer you to contact me in *',
        back: total.en.back,
        next: total.en.next,
    },
    ru: {
        phoneNumber: 'Номер телефона *',
        enterValidPhone: 'Пожалуйста, введите действующий телефонный номер',
        preferContact: 'Мне удобно общаться в *',
        back: total.ru.back,
        next: total.ru.next,
    },
    uk: {
        phoneNumber: 'Номер телефону *',
        enterValidPhone: 'Будь ласка, введіть діючий телефонний номер',
        preferContact: 'Мені зручніше спілкуватись у *',
        back: total.uk.back,
        next: total.uk.next,
    },
}
export const creationAccountPayments = {
    en: {
        title: 'Add banking information',
        sectionText: 'The cost of the program for patients will be 1000 UAH per month. ComeBack Mobility will pay you 50% of this for consultations',
        individualEntity: 'Individual',
        legalEntity: 'Legal entity',
        cardNumber: 'Your card number',
        cardNumberExpiryDate: 'Expiry date',
        recipient: 'Name of recipient',
        accountNumberOrIban: 'Account number or IBAN',
        edrpou: 'EDRPOU',

        cardNumberPlaceholder: '0000 0000 0000 0000',
        cardNumberExpiryDatePlaceholder: 'mm/yy',
        recipientPlaceholder: '"ComeBack Mobility" LTD',
        accountNumberOrIbanPlaceholder: 'UA112345678901234567890123456',
        edrpouPlaceholder: '32855961',

        cardNumberErrorText: 'Card number must be 16 digits',
        cardNumberErrorText2: 'Please enter the correct card number',
        cardNumberExpiryDateErrorText: 'Please enter correct value',
        cardNumberExpiryDateErrorText2: 'Card expired',
        recipientErrorText: 'You should enter from 2 to 100 symbols',
        accountNumberOrIbanErrorText: 'You should enter 29 symbols',
        accountNumberOrIbanErrorText2: 'Please enter the correct IBAN',
        edrpouErrorText: 'You should enter 8 or 10 symbols',
        edrpouErrorText2: 'Please enter the correct EDRPOU ',


        back: total.en.back,
        next: 'Done',
    },
    ru: {
        title: 'Добавьте платёжную информацию',
        sectionText: 'Стоимость программы для пациента будет 1000 грн в месяц и 50% от этой суммы ComeBack Mobility будет платить Вам за консультации',
        individualEntity: 'Физическое лицо',
        legalEntity: 'Юридическое лицо',
        cardNumber: 'Ваша карта',
        cardNumberExpiryDate: 'Срок',
        recipient: 'Получатель',
        accountNumberOrIban: 'Розрахунковий рахунок або IBAN',
        edrpou: 'ЕГРПОУ',

        cardNumberPlaceholder: '0000 0000 0000 0000',
        cardNumberExpiryDatePlaceholder: 'мм/гг',
        recipientPlaceholder: 'ООО "ComeBack Mobility"',
        accountNumberOrIbanPlaceholder: 'UA112345678901234567890123456',
        edrpouPlaceholder: '32855961',

        cardNumberErrorText: 'Номер карты должен состоять из 16 цифр',
        cardNumberErrorText2: 'Пожалуйста, введите правильный номер карты',
        cardNumberExpiryDateErrorText: 'Пожалуйста, введите правильное значение',
        cardNumberExpiryDateErrorText2: 'Срок действия карты истёк',
        recipientErrorText: 'Введите от 2 до 100 символов',
        accountNumberOrIbanErrorText: 'Введите 29 символов',
        accountNumberOrIbanErrorText2: 'Пожалуйста, введите корректный IBAN',
        edrpouErrorText: 'Введите 8 или 10 символов',
        edrpouErrorText2: 'Пожалуйста, введите корректный ЕГРПОУ',



        back: total.ru.back,
        next: 'Готово',
    },
    uk: {
        title: 'Додайте платіжну інформацію',
        sectionText: 'Вартість програми для пацієнта буде 1000 грн в місяць і 50% від цієї суми ComeBack Mobility буде платити Вам за консультації',
        individualEntity: 'Фізична особа',
        legalEntity: 'Юридична особа',
        cardNumber: 'Ваша карта',
        cardNumberExpiryDate: 'Термін',
        recipient: 'Одержувач',
        accountNumberOrIban: 'Розрахунковий рахунок або IBAN',
        edrpou: 'ЄДРПОУ',

        cardNumberPlaceholder: '0000 0000 0000 0000',
        cardNumberExpiryDatePlaceholder: 'мм/рр',
        recipientPlaceholder: 'ООО "ComeBack Mobility"',
        accountNumberOrIbanPlaceholder: 'UA112345678901234567890123456',
        edrpouPlaceholder: '32855961',

        cardNumberErrorText: 'Номер карти повинен складатися з 16 цифр',
        cardNumberErrorText2: 'Будь ласка, введіть правильний номер карти',
        cardNumberExpiryDateErrorText: 'Будь ласка, введіть правильне значення',
        cardNumberExpiryDateErrorText2: 'Термін дії карти закінчився',
        recipientErrorText: 'Ви повинні ввести від 2 до 100 символів',
        accountNumberOrIbanErrorText: 'Ви повинні ввести 29 символів',
        accountNumberOrIbanErrorText2: 'Будь ласка, введіть коректний IBAN',
        edrpouErrorText: 'Ви повинні ввести 8 або 10 символів',
        edrpouErrorText2: 'Будь ласка, введіть коректний ЄДРПОУ',


        back: total.uk.back,
        next: 'Готово',
    },
}
export const deleteAccount = {
    en: {
        deleteAccount: 'Delete my account',
        deleteAccountButton: 'Delete my account',
        preventDeleteText: 'Sorry, you can’t delete your account while you have patients in progress',
        ok: 'OK',
        deleteAccountText: 'Are you sure you want to delete all your data?',
        confirmDeleteButton: 'Delete',
        cancel: 'Cancel',
    },
    ru: {
        deleteAccount: 'Удалить аккаунт',
        deleteAccountButton: 'Удаление аккаунта',
        preventDeleteText: 'К сожалению, Вы не можете удалить свой аккаунт, пока у вас есть пациенты',
        ok: 'Хорошо',
        deleteAccountText: 'Вы уверены, что хотите удалить все ваши данные?',
        confirmDeleteButton: 'Удалить',
        cancel: 'Отмена',
    },
    uk: {
        deleteAccount: 'Видалити акаунт',
        deleteAccountButton: 'Видалення акаунту',
        preventDeleteText: 'На жаль, Ви не можете видалити свій обліковий запис, поки у вас є пацієнти',
        ok: 'Добре',
        deleteAccountText: 'Ви впевнені, що хочете видалити всі ваші дані?',
        confirmDeleteButton: 'Видалити',
        cancel: 'Скасувати',
    }
}
export const initialScreenWrapper = {
    en: {
        accountRegistrationText: 'Account registration',
        enterAccountText: 'Enter account',
        continueGoogle: 'Continue with Google',
        continueApple: 'Continue with Apple',
        digitalAssistant: 'Your digital assistant',
        decorText: 'You will be able to monitor how your patients weight bearing the injured leg and see the number of steps taken',
    },
    ru: {
        accountRegistrationText: 'Регистрация аккаунта',
        enterAccountText: 'Вход в аккаунт',
        continueGoogle: 'Продолжить через Google',
        continueApple: 'Продолжить через Apple',
        digitalAssistant: 'Ваш цифровой ассистент',
        decorText: 'Вы сможете следить за тем, как Ваши пациенты нагружают травмированную ногу и видеть количество пройденных шагов ',
    },
    uk: {
        accountRegistrationText: 'Реєстрація аккаунта',
        enterAccountText: 'Вхід до акаунту',
        continueGoogle: 'Продовжити через Google',
        continueApple: 'Продовжити через Apple',
        digitalAssistant: 'Ваш цифровий асистент',
        decorText: 'Ви зможете стежити за тим, як Ваші пацієнти навантажують травмовану ногу і бачити кількість пройдених кроків',
    }
}
export const mainHeader = {
    en: {
        copyright: 'All rights reserved',
    },
    ru: {
        copyright: 'Все права защищены',
    },
    uk: {
        copyright: 'Всі права захищені',
    }
}
export const mainNav = {
    en: {
        myPatients: 'My patients',
        consultations: 'Consultations',
        notifications: 'Notifications',
        settings: 'Settings',
        support: 'Contact Support',
    },
    ru: {
        myPatients: 'Мои пациенты',
        consultations: 'Консультации',
        notifications: 'Уведомления',
        settings: 'Настройки',
        support: 'Служба поддержки',
    },
    uk: {
        myPatients: 'Мої пацієнти',
        consultations: 'Консультації',
        notifications: 'Повідомлення',
        settings: 'Налаштування',
        support: 'Служба підтримки',
    }
}
export const patientCard = {
    en: {
        payment: 'Awaiting start',
        start_at: 'Awaiting start',
        in_progress: 'In Progress',
        completed: 'Rehabilitated',
        canceled: 'Canceled',
        hidePatientData: 'Hide patient data',
        showPatientData: 'Show all information',
        availableInTelegram: 'Available in Telegram',
        injury: 'Injury:',
        surgery: 'Surgery:',
        startProgram: 'Start of the program:',
        loadStart: 'Weight bearing start:',
        age: 'Age:',
        height: 'Height, inch:',
        weight: 'Weight, lbs:',
        bmi: 'BMI:',
        operational: 'Surgical treatment',
        conservation: 'Therapy treatment',
    },
    ru: {
        payment: 'Ожидаем начала',
        start_at: 'Ожидаем начала',
        in_progress: 'Реабилитируется',
        completed: 'Восстановлен',
        canceled: 'Отменено',
        hidePatientData: 'Скрыть данные пациента',
        showPatientData: 'Показать всю информацию',
        availableInTelegram: 'Доступен в  Telegram',
        injury: 'Травма:',
        surgery: 'Операция:',
        startProgram: 'Старт программы:',
        loadStart: 'Начало нагрузки:',
        age: 'Возраст:',
        height: 'Рост, см:',
        weight: 'Вес, кг:',
        bmi: 'ИМТ:',
        operational: 'Оперативное лечение',
        conservation: 'Консервативное лечение',
    },
    uk: {
        payment: 'Чекаємо початку',
        start_at: 'Чекаємо початку',
        in_progress: 'Реабілітується',
        completed: 'Відновлений',
        canceled: 'Скасовано',
        hidePatientData: 'Приховати дані пацієнта',
        showPatientData: 'Показати всю інформацію',
        availableInTelegram: 'Можна користуватися в Telegram',
        injury: 'Травма:',
        surgery: 'Операція:',
        startProgram: 'Старт програми:',
        loadStart: 'Початок навантаження:',
        age: 'Вік:',
        height: 'Зріст, см:',
        weight: 'Вага, кг:',
        bmi: 'ІМТ:',
        operational: 'Оперативне лікування',
        conservation: 'Консервативне лікування',
    }
}
export const patientRehabilitationProgram = {
    en: {
        rehabilitationProgram: 'Rehabilitation program',
        no_pressure: 'Non weight bearing',
        stable_pressure: 'Partial weight bearing',
        increment_pressure: 'Increase weight bearing',
        load: 'Weight bearing',
        changeCurrentLoad: 'Change the current weight bearing',
        overStatus: 'Stage is over',
        currentStatus: 'Current stage',
        nextStatus: 'Next stage',

    },
    ru: {
        rehabilitationProgram: 'Программа восстановления',
        no_pressure: 'Без нагрузки',
        stable_pressure: 'Постоянная нагрузка',
        increment_pressure: 'Нарастающая нагрузка',
        load: 'Нагрузка',
        changeCurrentLoad: 'Изменить текущую нагрузку',
        overStatus: 'Этап окончен',
        currentStatus: 'Текущий этап',
        nextStatus: 'Следующий этап',

    },
    uk: {
        rehabilitationProgram: 'Програма відновлення',
        no_pressure: 'Без навантаження',
        stable_pressure: 'Постійне навантаження',
        increment_pressure: 'Зростаюче навантаження',
        load: 'Навантаження',
        changeCurrentLoad: 'Змінити поточне навантаження',
        overStatus: 'Етап закінчено',
        currentStatus: 'Поточний етап',
        nextStatus: 'Наступний етап',

    }
}
export const patientsFilter = {
    en: {
        payment: 'Payment',
        start_at: 'Awaiting start',
        in_progress: 'In Progress',
        completed: 'Rehabilitated',
        canceled: 'Canceled',
        clearAll: 'Clear all',
    },
    ru: {
        payment: 'Оплата',
        start_at: 'Ожидаем начала',
        in_progress: 'Реабилитируется',
        completed: 'Восстановлен',
        canceled: 'Отменено',
        clearAll: 'Очистить все',
    },
    uk: {
        payment: 'Оплата',
        start_at: 'Чекаємо початку',
        in_progress: 'Реабілітується',
        completed: 'Відновлений',
        canceled: 'Скасовано',
        clearAll: 'Очистити всі',
    }
}
export const patientsSearch = {
    en: {
        searchPlaceholder: 'Enter patient surname or diagnosis',
        patients: 'Patients',
        diagnosis: 'Diagnosis',
        nothingFound: 'No matches found',
    },
    ru: {
        searchPlaceholder: 'Введите фамилию пациента или диагноз',
        patients: 'Пациенты',
        diagnosis: 'Диагнозы',
        nothingFound: 'Ничего не найдено по данному запросу',
    },
    uk: {
        searchPlaceholder: 'Введіть прізвище пацієнта або діагноз',
        patients: 'Пацієнти',
        diagnosis: 'Діагнози',
        nothingFound: 'Нічого не знайдено по даному запиту',
    }
}
export const patientSteps = {
    en: {
        patientChartTitle: 'Step and weight bearing data',
        day: 'Daily',
        week: 'Weekly',
        month: 'Monthly',
        allPeriod: 'Whole period',
        daysUsing: 'Days of using crutch tips',
        insufficientLoad: 'Insufficient weight bearing',
        recommendedLoad: 'Recommended weight bearing',
        excessiveLoad: 'Excessive weight bearing',
        totalSteps: 'Total steps',
        loadPercentage: 'Load percentage',
    },
    ru: {
        patientChartTitle: 'Данные о шагах и нагрузки',
        day: 'День',
        week: 'Неделя',
        month: 'Месяц',
        allPeriod: 'Весь период',
        daysUsing: 'Дни использования насадок',
        insufficientLoad: 'Недостаточная нагрузка',
        recommendedLoad: 'Рекомендуемая нагрузка',
        excessiveLoad: 'Чрезмерная нагрузка',
        totalSteps: 'Всего шагов',
        loadPercentage: 'Процент нагрузки',
    },
    uk: {
        patientChartTitle: 'Дані про кроки і навантаження',
        day: 'День',
        week: 'Тиждень',
        month: 'Місяць',
        allPeriod: 'Весь період',
        daysUsing: 'Дні використання насадок',
        insufficientLoad: 'Недостатнє навантаження',
        recommendedLoad: 'Рекомендоване навантаження',
        excessiveLoad: 'Надмірне навантаження',
        totalSteps: 'Всього кроків',
        loadPercentage: 'Відсоток навантаження',
    }
}
export const popupVerifyEmail = {
    en: {
        resendEmail: 'Resend email',
        validFor: 'Valid for 0:',
        confirmEmail: 'Confirm your email!',
        confirmEmailText: email => <>An email with a confirmation link has been sent to <span className="sign-up-highlight">{email}</span>.<br /><span className="sign-up-highlight">Please click the link to finish registering your account</span></>,
        didntReceive: 'Didn\'t receive the email?',
    },
    ru: {
        resendEmail: 'Отправить повторно',
        validFor: 'Доступно через 0:',
        confirmEmail: 'Подтвердите регистрацию!',
        confirmEmailText: email => <>На Ваш адрес электронной почты <span className="sign-up-highlight">{email}</span> отправлено письмо со ссылкой для подтверждения.<br /><span className="sign-up-highlight">Пожалуйста, проверьте его</span></>,
        didntReceive: 'Не пришло письмо?',
    },
    uk: {
        resendEmail: 'Надіслати повторно',
        validFor: 'Доступно через 0:',
        confirmEmail: 'Підтвердіть реєстрацію!',
        confirmEmailText: email => <>На Вашу адресу електронної пошти <span className="sign-up-highlight">{email}</span> надісланий лист з посиланням для підтвердження.<br /><span className="sign-up-highlight">Будь ласка, перевірте його</span></>,
        didntReceive: 'Не прийшов лист?',
    },
}
export const profile = {
    en: {
        logOut: 'Log Out',
    },
    ru: {
        logOut: 'Выйти',
    },
    uk: {
        logOut: 'Вийти',
    }
}
export const registrationSuccessful = {
    en: {
        registrationSuccessful: 'Registration was successful. Thank you!',
    },
    ru: {
        registrationSuccessful: 'Регистрация прошла успешно, Спасибо!',
    },
    uk: {
        registrationSuccessful: 'Реєстрація пройшла успішно, дякуємо!',
    }
}
export const addPatient = {
    en: {
        newPatient: 'New patient',
        patientContacts: 'Patient contact information',
        patientCard: 'Patient card',
        rehabilitationProgram: 'Rehabilitation program',
        stepInfo: (currentStep, stepsLength) => `Step ${currentStep + 1} of ${stepsLength}`,
        close: 'Close',
        back: 'Back',
        next: 'Next',
        done: 'Send the program',
        save: 'Save',
        enterPatientsPhone: 'Enter the patient\'s phone number',
        linkToTheMobileApp: 'A link to the mobile app with the recovery program will be sent to the patient via messenger or SMS.',
        patientCanBeContacted: 'Patient can be contacted via',
        specifyTypeTreatment: 'Specify the type of treatment',
        surgery: 'Surgery',
        therapy: 'Therapy',
        diagnosis: 'Diagnosis',
        diagnosisPlaceholder: 'Please enter diagnosis name or ICD-10 number.',
        diagnosisError: 'Please enter the correct diagnosis',
        weeks: 'Weeks',
        nonWeightBearing: 'Non weight bearing',
        partialWeightBearing: 'Partial weight bearing',
        increaseWeightBearing: 'Increase weight bearing',
        loadInfo: (from, to) => `from ${from} to ${to}`,
        setInitialLoad: 'Set initial weight bearing',
        setMaximumLoad: 'Set maximum weight bearing',
        setBothLoad: 'Set initial and maximum weight bearing',
        totalProgramDuration: 'Total program duration:',
        programCost: 'Program cost:',
        uah: 'UAH',
        addPatient: 'Add one more',
        patientAdded: 'The patient has been added!',
        checkoutText1: (number, messenger) => <>A link to the application for the rehabilitation program will be sent to the patient <span className="highlight">"{number}" via {messenger === 'sms' ? messenger.substring(0, 1).toUpperCase() + messenger.substring(1) : messenger}</span></>,
        yourEarnings: 'Your earnings',
        cardMessage: 'After the patient pays, your compensation will be sent to card:',
        accountMessage: 'After the patient pays, your compensation will be sent to account:',
        enterValidPhone: 'Please enter a valid phone number',
        from: 'from',
        to: 'to',
    },
    ru: {
        newPatient: 'Новый пациент',
        patientContacts: 'Контакты пациента',
        patientCard: 'Карта пациента',
        rehabilitationProgram: 'Программа восстановления',
        stepInfo: (currentStep, stepsLength) => `Шаг ${currentStep + 1} из ${stepsLength}`,
        close: 'Закрыть',
        back: 'Назад',
        next: 'Далее',
        done: 'Отправить программу',
        save: 'Сохранить',
        enterPatientsPhone: 'Введите номер телефона пациента',
        linkToTheMobileApp: 'Ссылка на мобильное приложение с программой восстановления придет пациенту в мессенджер или по SMS.',
        patientCanBeContacted: 'Пациент доступен в',
        specifyTypeTreatment: 'Укажите тип лечения',
        surgery: 'Оперативное',
        therapy: 'Консервативное',
        diagnosis: 'Диагноз',
        diagnosisPlaceholder: 'Введите диагноз или номер из МКБ-10',
        diagnosisError: 'Пожалуйста, введите правильный диагноз',
        weeks: 'неделя',
        nonWeightBearing: 'Без нагрузки',
        partialWeightBearing: 'Постоянная нагрузка',
        increaseWeightBearing: 'Нарастающая нагрузка',
        loadInfo: (from, to) => `с ${from} до ${to}`,
        setInitialLoad: 'Редактирование начальной нагрузки',
        setMaximumLoad: 'Редактирование максимальной нагрузки',
        setBothLoad: 'Редактирование начальной и максимальной нагрузки',
        totalProgramDuration: 'Общая длительность программы:',
        programCost: 'Стоимость программы:',
        uah: 'грн',
        addPatient: 'Добавить еще пациента',
        patientAdded: 'Пациент добавлен!',
        checkoutText1: (number, messenger) => <>Ссылка на приложение с программой восстановления придёт пациенту <span className="highlight">"{number}" в {messenger === 'sms' ? messenger.substring(0, 1).toUpperCase() + messenger.substring(1) : messenger}</span></>,
        yourEarnings: 'Ваш доход',
        cardMessage: 'Когда пациент оплатит - деньги придут на карту:',
        accountMessage: 'Когда пациент оплатит - деньги придут на счёт:',
        enterValidPhone: 'Пожалуйста, введите действующий телефонный номер',
        from: 'с',
        to: 'до',
    },
    uk: {
        newPatient: 'Новий пацієнт',
        patientContacts: 'Контакти пацієнта',
        patientCard: 'Карта пацієнта',
        rehabilitationProgram : 'Програма відновлення',
        stepInfo: (currentStep, stepsLength) => `Крок ${currentStep + 1} з ${stepsLength}`,
        close: 'Закрити',
        back: 'Назад',
        next: 'Далі',
        done: 'Надіслати програму',
        save: 'Зберегти',
        enterPatientsPhone: 'Введіть номер телефона пацієнта',
        linkToTheMobileApp: 'Посилання на мобільний додаток з програмою відновлення прийде пацієнту в месенджер або по SMS.',
        patientCanBeContacted: 'Пацієнт доступний в',
        specifyTypeTreatment: 'Вкажіть тип лікування',
        surgery: 'Оперативне',
        therapy: 'Консервативне',
        diagnosis: 'Діагноз',
        diagnosisPlaceholder: 'Введіть діагноз або номер з МКБ-10',
        diagnosisError: 'Будь ласка, введіть правильний діагноз',
        weeks: 'тиждень',
        nonWeightBearing: 'Без навантаження',
        partialWeightBearing: 'Постійне навантаження',
        increaseWeightBearing: 'Зростаюче навантаження',
        loadInfo: (from, to) => `з ${from} до ${to}`,
        setInitialLoad: 'Редагування початкового навантаження',
        setMaximumLoad: 'Редагування максимального навантаження',
        setBothLoad: 'Редагування початкового та максимального навантаження',
        totalProgramDuration: 'Загальна тривалість програми:',
        programCost: 'Вартість програми:',
        uah: 'грн',
        addPatient: 'Додати ще пацієнта',
        patientAdded: 'Пацієнт доданий!',
        checkoutText1: (number, messenger) => <>Посилання на додаток до програми відновлення прийде пацієнту <span className="highlight">"{number}" в {messenger === 'sms' ? messenger.substring(0, 1).toUpperCase() + messenger.substring(1) : messenger}</span></>,
        yourEarnings: 'Ваш дохід',
        cardMessage: 'Буде відправлено на карту:',
        accountMessage: 'Буде відправлено на рахунок:',
        enterValidPhone: 'Будь ласка, введіть діючий телефонний номер',
        from: 'з',
        to: 'до',
    }
}
export const selectCountry = {
    en: {
        signInUp: 'Sign In/Sign Up',
        chooseYourCountry: 'Choose your country...',
        continue: 'Continue',
        selectAnotherCountry: 'Enter another country',
    },
    ru: {
        signInUp: 'Вход/Регистрация',
        chooseYourCountry: 'Выберите страну...',
        continue: 'Продолжить',
        selectAnotherCountry: 'Введите другую страну',
    },
    uk: {
        signInUp: 'Вхід/Реєстрація',
        chooseYourCountry: 'Виберіть країну...',
        continue: 'Продовжити',
        selectAnotherCountry: 'Введіть іншу країну',
    },
}
export const separatorOr = {
    en: {
        or: 'Or',
    },
    ru: {
        or: 'Или',
    },
    uk: {
        or: 'або',
    },
}
export const supportLink = {
    en: {
        help: 'Help',
    },
    ru: {
        help: 'Помощь',
    },
    uk: {
        help: 'Допомога',
    },
}
export const otpEnterNumber = {
    en: {
        signInUp: 'Sign In/Sign Up',
        continue: 'Continue',
        phoneNumber: 'Enter your number',
        enterValidPhone: 'Please enter a valid phone number',
        byRegistering: 'By continuing, I agree to the ',
        terms: 'Terms of Service',
        privacy: 'Privacy Policy',
        and: 'and',
    },
    ru: {
        signInUp: 'Вход/Регистрация',
        continue: 'Продолжить',
        phoneNumber: 'Номер телефона',
        enterValidPhone: 'Пожалуйста, введите действующий телефонный номер',
        byRegistering: 'Продолжая, я принимаю ',
        terms: 'Условия использования',
        privacy: 'Политику конфиденциальности',
        and: 'и',
    },
    uk: {
        signInUp: 'Вхід/Реєстрація',
        continue: 'Продовжити',
        phoneNumber: 'Номер телефону',
        enterValidPhone: 'Будь ласка, введіть діючий телефонний номер',
        byRegistering: 'Продовжуючи, я приймаю ',
        terms: 'Умови використання',
        privacy: 'Політику конфіденційності',
        and: 'та',
    },
}
export const otpSms = {
    en: {
        smsWasSent: phone => <>An SMS code was sent to <span className="otp-sms-phone-number">{phone}</span></>,
        enterVerificationCode: 'Enter verification code from SMS',
        didNotReceive: 'Did not receive the code?',
        availableThrough: time => `Available through 0:${time}`,
        resendCode: 'Resend code',
        continue: 'Continue',
        back: 'Back',
    },
    ru: {
        smsWasSent: phone => <>На номер <span className="otp-sms-phone-number">{phone}</span> отправлено SMS с кодом</>,
        enterVerificationCode: 'Введите код проверки из SMS',
        didNotReceive: 'Выслать код еще раз?',
        availableThrough: time => `Доступно через 0:${time}`,
        resendCode: 'Отправить повторно',
        continue: 'Продолжить',
        back: 'Назад',
    },
    uk: {
        smsWasSent: phone => <>На номер <span className="otp-sms-phone-number">{phone}</span> надіслано SMS з кодом</>,
        enterVerificationCode: 'Введіть код перевірки з SMS',
        didNotReceive: 'Надіслати код повторно?',
        availableThrough: time => `Доступно через 0:${time}`,
        resendCode: 'Надіслати повторно',
        continue: 'Продовжити',
        back: 'Назад',
    },
}

/*
* Other
* */
export const specializationTranslations = {
    'us-en': {
        emergencyDepartment: 'Emergency Department',
        familyPractice: 'Family Practice',
        orthopedics: 'Orthopedics',
        sportsMedicine: 'Sports Medicine',
        spine: 'Spine',
        physicalMedicineAndRehabilitation: 'Physical Medicine and Rehabilitation',
        podiatry: 'Podiatry',
        neurology: 'Neurology',
        other: 'Other'
    },
    en: {
        orthopedist: 'Orthopedist - traumatologist',
        rehabilitologist: 'Rehabilitologist',
        neurologist: 'Neurologist',
        physiotherapist: 'Physiotherapist',
        physicalTherapyInstructor: 'Physical therapy instructor',
        other: 'Other',
    },
    ru: {
        orthopedist: 'Ортопед - травматолог',
        rehabilitologist: 'Реабилитолог',
        neurologist: 'Невропатолог',
        physiotherapist: 'Физический терапевт',
        physicalTherapyInstructor: 'Инструктор ЛФК',
        other: 'Другое',
    },
    uk: {
        orthopedist: 'Ортопед - травматолог',
        rehabilitologist: 'Реабілітолог',
        neurologist: 'Невропатолог',
        physiotherapist: 'Фізичний терапевт',
        physicalTherapyInstructor: 'Інструктор ЛФК',
        other: 'Інше',
    }
}
export const positionTranslations = {
    'us-en': {
        physician: 'Physician',
        surgeon: 'Surgeon',
        nursePractitioner: 'Nurse Practitioner',
        physicianAssistant: 'Physician Assistant',
        physicalTherapist: 'Physical Therapist',
        athleticTrainer: 'Athletic Trainer',
        other: 'Other'
    },
    en: {
        physician: 'Physician',
        headDepartment: 'Head of department',
        centerManager: 'Center manager',
        intern: 'Intern',
        resident: 'Resident',
        assistantProfessor: 'Assistant Professor',
        professor: 'Professor',
        instructor: 'Instructor',
        seniorInstructor: 'Senior Instructor',
        rehabilitator: 'Rehabilitator',
        assistant: 'Assistant',
        other: 'Other position',
    },
    ru: {
        physician: 'Врач',
        headDepartment: 'Заведующий',
        centerManager: 'Руководитель центра',
        intern: 'Интерн',
        resident: 'Ординатор',
        assistantProfessor: 'Доцент',
        professor: 'Профессор',
        instructor: 'Инструктор',
        seniorInstructor: 'Старший инструктор',
        rehabilitator: 'Реабилитолог',
        assistant: 'Ассистент',
        other: 'Другая должность',
    },
    uk: {
        physician: 'Лікар',
        headDepartment: 'Завідувач',
        centerManager: 'Керівник центру',
        intern: 'Інтерн',
        resident: 'Ординатор',
        assistantProfessor: 'Доцент',
        professor: 'Професор',
        instructor: 'Інструктор',
        seniorInstructor: 'Старший інструктор',
        rehabilitator: 'Реабілітолог',
        assistant: 'Асистент',
        other: 'Інша посада',
    }
}
export const noInternet = {
    en: 'No internet connection now',
    ru: 'Нет подключения к Интернету сейчас',
    uk: 'Зараз немає з’єднання з Інтернетом'
}