import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {updatePatientField} from "../../store/actions";
import './PatientCard.css'
import {ReactComponent as PhoneIcon} from "../../images/phone-icon.svg";
import {ReactComponent as PatchIcon} from "../../images/patch-icon.svg";
import {ReactComponent as List2Icon} from "../../images/list2-icon.svg";
import {ReactComponent as PatientPhotoEmpty} from "../../images/patient-photo-empty.svg";
import {ReactComponent as ManBody1} from "../../images/man-body-1.svg";
import {ReactComponent as ManBody2} from "../../images/man-body-2.svg";
import {ReactComponent as ManBody3} from "../../images/man-body-3.svg";
import {ReactComponent as ManBody4} from "../../images/man-body-4.svg";
import {ReactComponent as ManBody5} from "../../images/man-body-5.svg";
import {ReactComponent as ManBody6} from "../../images/man-body-6.svg";
import {ReactComponent as WomanBody1} from "../../images/woman-body-1.svg";
import {ReactComponent as WomanBody2} from "../../images/woman-body-2.svg";
import {ReactComponent as WomanBody3} from "../../images/woman-body-3.svg";
import {ReactComponent as WomanBody4} from "../../images/woman-body-4.svg";
import {ReactComponent as WomanBody5} from "../../images/woman-body-5.svg";
import {ReactComponent as WomanBody6} from "../../images/woman-body-6.svg";
import dateForView from "../../helpers/dateForView";
import {patientCard as translations} from "../../translations";
import _ from "../../helpers/_";
import getFlow from "../../helpers/getFlow";


const patientBodyImages = {
    male: [<ManBody1 />, <ManBody2 />, <ManBody3 />, <ManBody4 />, <ManBody5 />, <ManBody6 />],
    female: [<WomanBody1 />, <WomanBody2 />, <WomanBody3 />, <WomanBody4 />, <WomanBody5 />, <WomanBody6 />]
}
const getPatientBodyImageIndex = bmi => {
    if (bmi < 18.5) {
        return 0
    } else if (bmi < 25) {
        return 1
    } else if (bmi < 30) {
        return 2
    } else if (bmi < 35) {
        return 3
    } else if (bmi < 40) {
        return 4
    } else if (bmi >= 40) {
        return 5
    }
    return 3
}

export default () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const cardExpander = useSelector(state => state.patient.cardExpander)
    const patient = useSelector(state => state.patient.patientData)
    const country = useSelector(state => state.app.doctorData.country)
    const flow = getFlow(country)
    const isUsFlow = flow === 'us'
    const windowWidth = useSelector(state => state.app.windowWidth)
    const texts = translations[language]

    /*
    * Handlers
    * */
    const expanderClickHandler = () => dispatch(updatePatientField('cardExpander', !cardExpander))

    /*
    * Data
    * */
    const locale = isUsFlow ? 'en-US' : 'ru'
    const patientFullName = patient.first_name && patient.last_name ? patient.first_name + ' ' + patient.last_name : patient.phone_number
    const currentProgram = patient.current_program || {}
    const patientPhoto = patient.photo ? <img src={patient.photo} /> : <PatientPhotoEmpty />
    const weightMeasure = patient.weight_measure_type
    const weight = patient.weight
        ? weightMeasure === 'kg'
            ? +(+patient.weight).toFixed(1)
            : +(+patient.weight * 2.205).toFixed(1)
        : null
    const height = patient.height
        ? isUsFlow
            ? +(+patient.height / 2.54).toFixed(1)
            : +(+patient.height).toFixed(1)
        : null
    const typeTreatment = patient.type_treatment
    const patientBmi = weight && height ? +((weight) / Math.pow((patient.height / 100) , 2)).toFixed(1) : null
    const patientStatusTranslate = translations[language][patient.status]
    const patientCardWrapperClassName = 'patient-card-wrapper' + (cardExpander ? ' patient-card-wrapper--small' : '')
    const patientSex = (patient.sex === 'female' && 'female') || 'male'
    const patientBodyImage = patientBodyImages[patientSex][getPatientBodyImageIndex(patientBmi)]

    /*
    * Components
    * */
    const patientIdComponent = <div className="patient-id">#{patient.patient_id}</div>
    const patientPhotoComponent =
        <div className="patient-photo-wrapper">
            {patientPhoto}
            <div className={`patient-status patient-status-${patient.status}`}>
                {patientStatusTranslate}
            </div>
        </div>
    const patientFullNameComponent = <div className="patient-full-name">{patientFullName}</div>
    const patientCardSeparatorComponent = <div className="patient-card-separator" />
    const cardExpanderTextComponent = cardExpander
        ? <div className="patient-card-expander-text">{texts.showPatientData}</div>
        : <div className="patient-card-expander-text">{texts.hidePatientData}</div>
    const cardExpanderComponent =
        <>
            <button className="patient-card-expander" onClick={expanderClickHandler} />
            {cardExpanderTextComponent}
        </>
    const patientTraumaDateComponent = _(patient.trauma_date,
        <div className="patient-card-date-wrapper">
            <div className="patient-card-date-text">{texts.injury}</div>
            <div className="patient-card-date">{dateForView(patient.trauma_date, locale)}</div>
        </div>)
    const patientOperationDateComponent = _(patient.operation_date,
        <div className="patient-card-date-wrapper">
            <div className="patient-card-date-text">{texts.surgery}</div>
            <div className="patient-card-date">{dateForView(patient.operation_date, locale)}</div>
        </div>)
    const patientProgramStartDateComponent = _(patient.program_start_date,
        <div className="patient-card-date-wrapper">
            <div className="patient-card-date-text">{texts.startProgram}</div>
            <div className="patient-card-date">{dateForView(patient.program_start_date, locale)}</div>
        </div>)
    const patientLoadStartDateComponent = _(currentProgram.loading_start_date,
        <div className="patient-card-date-wrapper">
            <div className="patient-card-date-text">{texts.loadStart}</div>
            <div className="patient-card-date">{dateForView(currentProgram.loading_start_date, locale)}</div>
        </div>)

    const usDates = _(isUsFlow, <>
        <div className="patient-card-date-group">
            {patientTraumaDateComponent}
            {patientOperationDateComponent}
        </div>
        {patientCardSeparatorComponent}
        <div className="patient-card-date-group">
            {patientProgramStartDateComponent}
            {patientLoadStartDateComponent}
        </div>
    </>)
    const uaDates = _(!isUsFlow, <>
        <div className="patient-card-ua-date-group">
            {patientOperationDateComponent}
            {patientProgramStartDateComponent}
            {patientLoadStartDateComponent}
        </div>
        {patientCardSeparatorComponent}
    </>)


    /*
    * Returns
    * */
    if (cardExpander) {
        return (
            <div className={patientCardWrapperClassName}>
                <div className="patient-card">
                    {patientPhotoComponent}
                    <div>
                        {patientIdComponent}
                        {patientFullNameComponent}
                        <span className="patient-card-info-text">{patient.diagnosis}</span>
                    </div>
                    <div className="patient-card-dates-wrapper">
                        {_(isUsFlow, patientTraumaDateComponent)}
                        {patientOperationDateComponent}
                        {patientProgramStartDateComponent}
                        {patientLoadStartDateComponent}
                    </div>
                </div>
                {cardExpanderComponent}
            </div>
        )
    }
    if (windowWidth <= 1300) {
        return (
            <div className={patientCardWrapperClassName}>
                <div className="patient-card patient-card--tablet">
                    <div className="patient-top">
                        {patientIdComponent}
                        {patientPhotoComponent}
                        {patientFullNameComponent}
                    </div>

                    <div className="patient-more-details">
                        <div className="patient-card-info-items-wrapper">
                            <div className="patient-card-info-item-wrapper">
                                <div className="patient-card-info-img-wrapper"><PhoneIcon /></div>
                                <span className="patient-card-info-text">{patient.phone_number}</span>
                                {patient.messenger === 'telegram' ? <span className="patient-card-info-sub-text">{texts.availableInTelegram}</span> : null}
                            </div>

                            <div className="patient-card-info-item-wrapper">
                                <div className="patient-card-info-img-wrapper"><PatchIcon /></div>
                                <span className="patient-card-info-text">{texts[typeTreatment]}</span>
                            </div>

                            <div className="patient-card-info-item-wrapper">
                                <div className="patient-card-info-img-wrapper"><List2Icon /></div>
                                <span className="patient-card-info-text">{patient.diagnosis}</span>
                            </div>
                        </div>
                        <div className="patient-options-wrapper">
                            {_(patient.program_start_date,
                            <div className="patient-option-wrapper">
                                <div className="patient-option-text">{texts.startProgram}</div>
                                <div className="patient-option-value">{dateForView(patient.program_start_date, locale)}</div>
                            </div>)}
                            <div className="patient-option-wrapper">
                                <div className="patient-option-text">{texts.weight}</div>
                                <div className="patient-option-value">{weightMeasure === 'kg' && isUsFlow ? weight * 2.205 : weight}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {cardExpanderComponent}
            </div>
        );
    }

    return (
        <div className={patientCardWrapperClassName}>
            <div className="patient-card">

                {/*total info*/}
                <div className="patient-card-info">
                    <div className="patient-card-info-inner-wrapper">
                        {patientIdComponent}
                        {patientPhotoComponent}
                        {patientFullNameComponent}
                        {patientCardSeparatorComponent}
                        <div className="patient-card-info-items-wrapper">
                            <div className="patient-card-info-item-wrapper">
                                <div className="patient-card-info-img-wrapper"><PhoneIcon /></div>
                                <span className="patient-card-info-text">{patient.phone_number}</span>
                                {patient.messenger === 'telegram' ? <span className="patient-card-info-sub-text">{texts.availableInTelegram}</span> : null}
                            </div>

                            <div className="patient-card-info-item-wrapper">
                                <div className="patient-card-info-img-wrapper"><PatchIcon /></div>
                                <span className="patient-card-info-text">{texts[typeTreatment]}</span>
                            </div>

                            <div className="patient-card-info-item-wrapper">
                                <div className="patient-card-info-img-wrapper"><List2Icon /></div>
                                <span className="patient-card-info-text">{patient.diagnosis}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/*details*/}
                <div className="patient-card-more-details">
                    <div className="patient-card-more-details-inner-wrapper">
                        {/*dates*/}
                        {usDates}
                        {uaDates}

                        {/*options*/}
                        <div className="patient-card-options-wrapper">
                            <div className="patient-card-option">
                                <span className="patient-card-option-text">{texts.height}</span>
                                <span className="patient-card-option-index">{height}</span>
                            </div>
                            <div className="patient-card-option">
                                <span className="patient-card-option-text">{texts.weight}</span>
                                <span className="patient-card-option-index">{weight}</span>
                            </div>
                            <div className="patient-card-option">
                                <span className="patient-card-option-text">{texts.bmi}</span>
                                <span className="patient-card-option-index">{patientBmi}</span>
                            </div>
                        </div>
                    </div>

                    {/*body image*/}
                    {patientBodyImage}
                </div>
            </div>
            {cardExpanderComponent}
        </div>
    )
}