import {createAccountSendEmail, setContentPopup} from "../../store/actions";
import {ReactComponent as LogoIcon} from "../../images/logo-initial-screen-icon.svg";
import Popup from "../Popup/Popup";
import {ReactComponent as EmojiIcon} from "../../images/emoji-icon.svg";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Notification from "../Notification/Notification";
import getLanguage from "../../helpers/getLanguage";
import {popupVerifyEmail as translations} from "../../translations";


export default () => {
    const dispatch = useDispatch()
    const email = useSelector(state => state.app.email)
    const isShowNotification = useSelector(state => state.app.notification.show)
    const notification = (isShowNotification && <Notification />) || null
    const language = getLanguage()
    const texts = translations[language]
    const [timer, setTimer] = useState(false)

    const timerSeconds = second => {
        const numberSecond = Number(second) - 1
        const stringSecond = String(numberSecond)
        if (numberSecond > 0) {
            const newTime = stringSecond.length === 1 ? '0' + numberSecond : numberSecond
            setTimeout(timerSeconds, 1000, newTime)
            setTimer(newTime)
        } else {
            setTimer(false)
        }
    }
    const resendHandler = () => {
        dispatch(createAccountSendEmail(() => timerSeconds(60)))
    }

    useEffect(() => () => dispatch(setContentPopup('')), [])

    let action = <button className="sign-up-resend" onClick={resendHandler} >{texts.resendEmail}</button>
    if (timer) {
        action = <div className="valid-for">{texts.validFor}{timer}</div>
    }

    return (
        <div className="registration-popup-wrapper">
            <LogoIcon />
            <Popup type="content">
                <EmojiIcon />
                <div className="popup-title">{texts.confirmEmail}</div>
                <div className="popup-text">{texts.confirmEmailText(email)}</div>
                <div className="didnt-receive">{texts.didntReceive}</div>
                {action}
            </Popup>
            {notification}
        </div>
    )
}