import React, {createRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {showFieldsErrors, signIn, updateField, updateFieldValidation} from "../../store/actions"
import '../../components/InitialScreenWrapper/InitialScreenWrapper.css'
import './SignInPage.css'
import ValidateField from "../../components/ValidateField/ValidateField";
import getLanguage from "../../helpers/getLanguage";
import waveEffect from "../../helpers/waveEffect";
import {ReactComponent as ArrowIcon} from "../../images/arrow-icon.svg";
import {ReactComponent as LoadingButton} from "../../images/loading-button.svg";
import {NavLink} from "react-router-dom";
import {signInPage as translations} from "../../translations";
import _ from "../../helpers/_";
import Notification from "../../components/Notification/Notification";
import {ReactComponent as LogoIcon} from "../../images/logo-initial-screen-icon.svg";
import GoogleApple from "../../components/GoogleApple/GoogleApple";
import InitialScreenRightSide from "../../components/InitialScreenRightSide/InitialScreenRightSide";
import SupportLink from "../../components/SupportLink/SupportLink";
import OtpEnterNumber from "../../components/OtpEnterNumber/OtpEnterNumber";
import OtpSms from "../../components/OtpSms/OtpSms";


export default () => {
    const dispatch = useDispatch()
    const email = useSelector(state => state.app.email)
    const password = useSelector(state => state.app.password)
    const requestInProgress = useSelector(state => state.app.requestInProgress)
    const validation = useSelector(state => state.validation.validation)
    const notification = useSelector(state => state.app.notification)
    const otpStageName = useSelector(state => state.app.otpStageName)

    const isStepInvalid = Object.values(validation).find(item => item.valid === false)
    const passwordField = createRef()
    const language = getLanguage()
    const texts = translations[language]
    const isShowNotification = notification.show
    const notificationComponent = _(isShowNotification, <Notification />)

    const logoIcon = _(otpStageName !== 'otp_sms', <LogoIcon className="initial-screen-logo"/>)
    const supportLink = _(otpStageName === 'otp_enter_phone' || otpStageName === 'otp_sms',
        <div className="initial-screen-support-link-wrapper">
            <SupportLink/>
        </div>
    )

    /*
    * Handlers
    * */
    const toggleType = ref => () => {
        ref.current.type === 'text'
            ? (ref.current.type = 'password')
            : (ref.current.type = 'text')
    }
    const fieldChangeHandler = fieldKey => e => {
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const value = e.target.value
        const preparedValue = fieldKey === 'email' ? value.toLowerCase() : value
        dispatch(updateField(fieldKey, preparedValue))
        setTimeout(function () {
            e.target.setSelectionRange(caretStart, caretEnd);
        })
    }
    const fieldBlurHandler = fieldKey => () => {
        const enableError = validation.hasOwnProperty(fieldKey) && !validation[fieldKey].showError
        if (enableError) {
            dispatch(updateFieldValidation({id: fieldKey, showError: true}))
        }
    }
    const signInButtonHandler = e => {
        waveEffect(e)
        if (isStepInvalid) {
            dispatch(showFieldsErrors())
        } else {
            dispatch(signIn({username: encodeURIComponent(email), password: encodeURIComponent(password)}, {text: texts.incorrectData}))
        }
    }

    /*
    * Validations
    * */
    const emailValidationRules = [
        [!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(email), texts.incorrectEmail],
    ]
    const emailShowError = (validation && validation.email && validation.email.showError) || false

    const passwordValidationRules = [
        [!(password.length > 7 && password.length < 25), texts.passLengthHint],
        [!/(?=.*[\d])(?=.*[A-Za-z])/.test(password), texts.passRuleHint]
    ]
    const passwordShowError = (validation && validation.password && validation.password.showError) || false

    /*
    * Base content
    * */
    const baseContent = _(otpStageName === 'us_flow',
        <>
            <div className="initial-screen-title">{texts.enterAccountText}</div>

            <GoogleApple type="sign-in" />

            <div className="initial-screen-data-separator">{texts.or}</div>

            <label htmlFor="email">
                <div className="tab-label-text">Email</div>
                <ValidateField id="email" validation={emailValidationRules} showError={emailShowError}>
                    <input id="email" type="text" value={email} placeholder="comeback@gmail.com"
                           onChange={fieldChangeHandler('email')}
                           onBlur={fieldBlurHandler('email')} />
                </ValidateField>
            </label>

            <label htmlFor="password">
                <div className="tab-label-text">
                    {texts.password}
                </div>
                <ValidateField id="password" validation={passwordValidationRules} showError={passwordShowError}>
                    <input id="password" type="password" value={password} placeholder={texts.enterPassword}
                           ref={passwordField}
                           onChange={fieldChangeHandler('password')}
                           onBlur={fieldBlurHandler('password')} />
                    <div className="pass-type-toggle" onClick={toggleType(passwordField)} />
                </ValidateField>
            </label>

            {/*<a href="#" className="forgot-password">{texts.forgotPassword}</a>*/}
            <button className={requestInProgress || isStepInvalid ? 'disabled is-button' : 'is-button'} onClick={signInButtonHandler}>
                {texts.signIn}
                {_(requestInProgress, <LoadingButton />)}
            </button>

            <div className="new-user">
                <span className="new-user-text">
                    {texts.newUser}
                </span>
                <NavLink to="/sign-up" className="new-user-link">
                    {texts.registerNow}
                    <ArrowIcon />
                </NavLink>
            </div>

        </>
    )

    /*
    * Select country content
    * */
    // @todo: old flow
    /*const selectCountryContent = _(otpStageName === 'select_country', <SelectCountry/>)*/

    /*
    * Ua flow enter county content
    * */
    const uaFlowEnterPhoneContent = _(otpStageName === 'otp_enter_phone', <OtpEnterNumber/>)

    /*
    * Ua flow sms
    * */
    const uaFlowSms = _(otpStageName === 'otp_sms', <OtpSms/>)

    /*
    * Base return
    * */
    return (
        <div className="initial-screen-wrapper">
            <div className="initial-screen-data-wrapper">

                {supportLink}

                <div className="initial-screen-data-container">

                    {logoIcon}

                    {baseContent}

                    {uaFlowEnterPhoneContent}

                    {uaFlowSms}

                </div>
            </div>

            <InitialScreenRightSide/>

            {notificationComponent}

        </div>
    )
}