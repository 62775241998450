import {useDispatch, useSelector} from "react-redux";
import getLanguage from "../../helpers/getLanguage";
import {otpEnterNumber as translations} from "../../translations";
import Button from "../Button/Button";
import React from "react";
import {signInOtp, updateCreationAccountField, updateField, updateFieldValidation} from "../../store/actions";
import './OtpEnterNumber.css'
import ValidateField from "../ValidateField/ValidateField";
import IntlTelInput from "react-intl-tel-input";

export default () => {
    const dispatch = useDispatch()
    const otpPhoneNumber = useSelector(state => state.app.otpPhoneNumber)
    const requestInProgress = useSelector(state => state.app.requestInProgress)
    const validation = useSelector(state => state.validation.validation)
    const language = getLanguage()
    const texts = translations[language]
    const isStepValid = Object.values(validation).every(item => item.valid === true)
    const isUkCounties = language === 'ru' || language === 'uk';
    const preferredCountries = isUkCounties ? ['ua', 'us'] : ['us', 'ua']
    const defaultCountry = isUkCounties ? 'ua' : 'us'

    /*
    * Handlers
    * */
    const moveCursorPositionHandler = phone => {
        setTimeout(() => {
            const element = document.getElementById('phoneNumber')
            if (element) {
                const cursorPosition = phone ? phone.length : element.value.length
                element.setSelectionRange(cursorPosition, cursorPosition)
            }
        }, 140)
    }
    const nextButtonClickHandler = () => dispatch(signInOtp())
    const phoneChangeHandler = (isValid, phone, info) => {
        phone = phone.replace(/[^\d+)(]/g, '')

        if (/^\+$|^\+\d+$/.test(phone) || phone === '') {
            dispatch(updateFieldValidation({id: 'phone_number', valid: isValid}))
            dispatch(updateField('otpPhoneNumber', phone))
            dispatch(updateCreationAccountField({key: 'country', value: info.iso2 }))
        }
    }
    const phoneBlurHandler = () => dispatch(updateFieldValidation({id: 'phone_number', showError: true}))


    /*
    * Props
    * */
    const nextButtonProps = {
        fullWidth: true,
        loading: requestInProgress,
        active: isStepValid,
        onClick: nextButtonClickHandler,
    }

    /*
    * Validation
    * */
    const phoneValidationRules = [[!(validation && validation.phone_number && validation.phone_number.valid), texts.enterValidPhone]]
    const phoneShowError = (validation && validation.phone_number && validation.phone_number.showError) || false

    /*
    * Base return
    * */
    return (
        <div className="otp-number-component">

            <div className="otp-number-title">{texts.signInUp}</div>

            <div className="tab-label">
                <div className="tab-label-text">
                    {texts.phoneNumber}
                </div>
                <ValidateField id="phone_number" showError={phoneShowError} validation={phoneValidationRules}>
                    <IntlTelInput autoHideDialCode={false} nationalMode={false} preferredCountries={preferredCountries}
                                  containerClassName="intl-tel-input"
                                  inputClassName="tab-input"
                                  fieldId="phoneNumber"
                                  defaultCountry={defaultCountry}
                                  onPhoneNumberChange={phoneChangeHandler}
                                  onPhoneNumberBlur={phoneBlurHandler}
                                  onSelectFlag={moveCursorPositionHandler}
                                  value={otpPhoneNumber || ''}
                    />
                </ValidateField>
            </div>

            <div className="otp-number-next-button-wrapper">
                <Button { ...nextButtonProps } >{texts.continue}</Button>
            </div>

            <div className="otp-number-consent">
                <span>{texts.byRegistering}</span>
                <a href={"https://comebackmobility.com/" + (language === 'uk' ? '' : language + '/') + "privacy-policy/#terms-and-conditions"} target="_blank">
                    {texts.terms}
                </a>
                <span className="consent-and">{texts.and}</span>
                <a href={"https://comebackmobility.com/" + (language === 'uk' ? '' : language + '/') + "privacy-policy/#privacy-policy"} target="_blank">
                    {texts.privacy}
                </a>
            </div>

        </div>
    )
}