import React, {createRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {
    createAccount,
    setContentPopup,
    showFieldsErrors,
    updateField,
    updateFieldValidation
} from "../../store/actions"
import '../../components/InitialScreenWrapper/InitialScreenWrapper.css'
import './SignUpPage.css'
import ValidateField from "../../components/ValidateField/ValidateField";
import getLanguage from "../../helpers/getLanguage";
import waveEffect from "../../helpers/waveEffect";
import {NavLink} from "react-router-dom";
import {ReactComponent as ArrowIcon} from "../../images/arrow-icon.svg";
import PopupVerifyEmail from "../../components/PopupVerifyEmail/PopupVerifyEmail";
import {signUpPage as translations} from "../../translations";
import _ from "../../helpers/_";
import {ReactComponent as LoadingButton} from "../../images/loading-button.svg";
import Notification from "../../components/Notification/Notification";
import {ReactComponent as LogoIcon} from "../../images/logo-initial-screen-icon.svg";
import GoogleApple from "../../components/GoogleApple/GoogleApple";
import InitialScreenRightSide from "../../components/InitialScreenRightSide/InitialScreenRightSide";
import SupportLink from "../../components/SupportLink/SupportLink";
import OtpEnterNumber from "../../components/OtpEnterNumber/OtpEnterNumber";
import OtpSms from "../../components/OtpSms/OtpSms";

export default () => {
    const dispatch = useDispatch()
    const email = useSelector(state => state.app.email)
    const password = useSelector(state => state.app.password)
    const validation = useSelector(state => state.validation.validation)
    const contentPopup = useSelector(state => state.app.contentPopup)
    const requestInProgress = useSelector(state => state.app.requestInProgress)
    const notification = useSelector(state => state.app.notification)
    const otpStageName = useSelector(state => state.app.otpStageName)

    const [passLength, changePassLength] = useState((password.length > 7 && password.length < 25))
    const [passSpecific, changePassSpecific] = useState(/(?=.*[\d])(?=.*[A-Za-z])/.test(password))

    const isStepInvalid = Object.values(validation).find(item => item.valid === false)
    const passwordField = createRef()
    const language = getLanguage()
    const texts = translations[language]
    const isShowNotification = notification.show
    const notificationComponent = _(isShowNotification, <Notification />)

    const logoIcon = _(otpStageName !== 'otp_sms', <LogoIcon className="initial-screen-logo"/>)
    const supportLink = _(otpStageName === 'otp_enter_phone' || otpStageName === 'otp_sms',
        <div className="initial-screen-support-link-wrapper">
            <SupportLink/>
        </div>
    )

    /*
    * Return popup
    * */
    if (contentPopup === 'register_account_email_send' && email && password) {
        return <PopupVerifyEmail />
    }

    /*
    * Handlers
    * */
    const toggleType = ref => () => {
        ref.current.type === 'text'
            ? (ref.current.type = 'password')
            : (ref.current.type = 'text')
    }
    const passwordChangeHandler = ({target: { value }}) => {
        if (value.length > 7 && value.length < 25) {
            changePassLength(true)
        } else {
            changePassLength(false)
        }
        if (/(?=.*[\d])(?=.*[A-Za-z])/.test(value)) {
            changePassSpecific(true)
        } else {
            changePassSpecific(false)
        }
        dispatch(updateField('password', value))
    }
    const fieldChangeHandler = fieldKey => e => {
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const value = e.target.value
        const preparedValue = fieldKey === 'email' ? value.toLowerCase() : value
        dispatch(updateField(fieldKey, preparedValue))
        setTimeout(function () {
            e.target.setSelectionRange(caretStart, caretEnd);
        })
    }
    const fieldBlurHandler = fieldKey => () => {
        const enableError = validation.hasOwnProperty(fieldKey) && !validation[fieldKey].showError
        if (enableError) {
            dispatch(updateFieldValidation({id: fieldKey, showError: true}))
        }
    }
    const signUpButtonHandler = e => {
        waveEffect(e)
        if (isStepInvalid) {
            dispatch(showFieldsErrors())
        } else {
            const data = {
                email: encodeURIComponent(email),
                //email: encodeURIComponent(email),
                password: encodeURIComponent(password)
            }
            //dispatch(createAccount({ email, password }, language)).then(
            dispatch(createAccount(data, language)).then(
                payload => {
                    if (payload === true) {
                        //localStorage.setItem('cbm_loginData', JSON.stringify({ email, password }))
                        localStorage.setItem('cbm_loginData', JSON.stringify(data))
                        dispatch(setContentPopup('register_account_email_send'))
                    }
                }
            )
        }
    }

    /*
    * Validations
    * */
    const emailValidationRules = [
        [!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(email), texts.incorrectEmail],
    ]
    const emailShowError = (validation && validation.email && validation.email.showError) || false

    const passwordValidationRules = [
        [!(password.length > 7 && password.length < 25), ''],
        [!/(?=.*[\d])(?=.*[A-Za-z])/.test(password), '']
    ]
    const passwordShowError = (validation && validation.password && validation.password.showError) || false

    /*
    * Base content
    * */
    const baseContent = _(otpStageName === 'us_flow',
        <>
            <div className="initial-screen-title">{texts.accountRegistrationText}</div>

            <GoogleApple type="sign-up" />

            <div className="initial-screen-data-separator">{texts.or}</div>

            <label htmlFor="email">
                <div className="tab-label-text">
                    Email
                </div>
                <ValidateField id="email" validation={emailValidationRules} showError={emailShowError}>
                    <input id="email" type="text" value={email} placeholder="comeback@gmail.com"
                           onChange={fieldChangeHandler('email')}
                           onBlur={fieldBlurHandler('email')} />
                </ValidateField>
            </label>

            <label htmlFor="password" className="sign-up-password-label">
                <div className="tab-label-text">
                    {texts.password}
                </div>
                <ValidateField id="password" validation={passwordValidationRules} showError={passwordShowError}>
                    <input id="password" type="password" value={password} placeholder={texts.enterPassword}
                           ref={passwordField}
                           onChange={passwordChangeHandler}
                           onBlur={fieldBlurHandler('password')} />
                    <div className="pass-type-toggle" onClick={toggleType(passwordField)} />
                </ValidateField>
                <div className="new-password-hints-wrapper">
                        <span className={`new-password-hint ${passLength ? 'new-password-hint--active' : ''}`}>
                            {texts.passLengthHint}
                        </span>
                    <span className={`new-password-hint ${passSpecific ? 'new-password-hint--active' : ''}`}>
                            {texts.passRuleHint}
                        </span>
                </div>
            </label>

            <button className={requestInProgress || isStepInvalid ? 'disabled is-button' : 'is-button'} onClick={signUpButtonHandler}>
                {texts.signUp}
                {_(requestInProgress, <LoadingButton />)}
            </button>

            <div className="consent">
                <span>{texts.byRegistering}</span>
                <a href={"https://comebackmobility.com/" + (language === 'uk' ? '' : language + '/') + "privacy-policy/#terms-and-conditions"} target="_blank">
                    {texts.terms}
                </a>
                <span className="consent-and">{texts.and}</span>
                <a href={"https://comebackmobility.com/" + (language === 'uk' ? '' : language + '/') + "privacy-policy/#privacy-policy"} target="_blank">
                    {texts.privacy}
                </a>
            </div>

            <div className="new-user">
                        <span className="new-user-text">
                            {texts.haveAccount}
                        </span>
                <NavLink to="/sign-in" className="new-user-link">
                    {texts.signIn}
                    <ArrowIcon />
                </NavLink>
            </div>
        </>)

    /*
    * Select country content
    * */
    // old flow
    /*const selectCountryContent = _(otpStageName === 'select_country', <SelectCountry/>)*/

    /*
    * Ua flow enter county content
    * */
    const uaFlowEnterPhoneContent = _(otpStageName === 'otp_enter_phone', <OtpEnterNumber/>)

    /*
    * Ua flow sms
    * */
    const uaFlowSms = _(otpStageName === 'otp_sms', <OtpSms/>)

    /*
    * Base return
    * */
    return (
        <div className="initial-screen-wrapper">
            <div className="initial-screen-data-wrapper">

                {supportLink}

                <div className="initial-screen-data-container">

                    {logoIcon}

                    {baseContent}

                    {uaFlowEnterPhoneContent}

                    {uaFlowSms}

                </div>

            </div>

            <InitialScreenRightSide/>

            {notificationComponent}

        </div>
    )
}