import {
    CREATION_ACCOUNT_ADD_WORKPLACE, CREATION_ACCOUNT_DELETE_WORKPLACE, DELETE_WORKPLACE,
    GET_CITES_GOOGLE,
    GET_HOSPITALS_GOOGLE,
    LOGOUT,
    RESTORE_STORE,
    UPDATE_CREATION_ACCOUNT_FIELD, UPDATE_CREATION_ACCOUNT_JOBS_FIELD_VALUE, UPDATE_JOBS_FIELD_VALUE,
} from "./types";
import getLanguage from "../helpers/getLanguage";

const initialState = {
    currentStep: 0,

    first_name: '',
    last_name: '',
    photo: '',
    sex: "string",
    birth_date: "string",
    email: "string",
    phone_number: '',
    messenger: '',
    specialization: 'disabled',
    specializationOther: '',
    position: 'disabled',
    positionOther: '',
    experience: '',
    jobs: [
        {
            id: 0,
            city: '',
            language: getLanguage(),
            institute: ''
        },
    ],
    country: 'ua',

    cites: [],
    workplaces: [],

    entity: 'individual',
    cardNumber: '',
    cardNumberExpiryDate: '',
    recipient: '',
    accountNumberOrIban: '',
    edrpou: '',
}

export const creationAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATION_ACCOUNT_DELETE_WORKPLACE: {
            const i = action.payload
            const jobs = [...state.jobs]
            jobs.splice(i, 1)

            return { ...state, jobs }
        }
        case CREATION_ACCOUNT_ADD_WORKPLACE: {
            const jobs = [...state.jobs, {
                id: action.payload.id,
                city: '',
                language: action.payload.language,
                institute: '',
            }]

            return { ...state, jobs }
        }
        case GET_HOSPITALS_GOOGLE: {
            if (state.currentStep === 0) {
                return state
            }

            const key = action.payload.key
            const i = key.replace(/\D/g, '')
            const search = action.payload.search
            const value = state.jobs[i].institute
            const data = action.payload.data

            if ((search === value || value === '') && data) {
                return { ...state,
                    workplaces: { ...state.workplaces,
                        [key]: data,
                    }
                }
            }
            return state
        }
        case GET_CITES_GOOGLE: {
            if (state.currentStep === 0) {
                return state
            }

            const key = action.payload.key
            const i = key.replace(/\D/g, '')
            const search = action.payload.search
            const value = state.jobs[i].city.split(',')[0]
            const data = action.payload.data

            if ((search === value || value === '') && data) {
                return { ...state,
                    cites: { ...state.cites,
                        [key]: data,
                    }
                }
            }
            return state
        }
        case UPDATE_CREATION_ACCOUNT_JOBS_FIELD_VALUE: {
            const i = action.payload.i
            const key = action.payload.key
            const value = action.payload.value
            const jobs = [ ...state.jobs]
            jobs[i] = { ...jobs[i],
                [key]: value,
            }

            return { ...state, jobs }
        }
        case UPDATE_CREATION_ACCOUNT_FIELD: {
            const {key, value} = action.payload
            return { ...state, [key]: value}
        }
        case RESTORE_STORE:
            return action.payload.creationAccount
        case LOGOUT: return initialState
        default: return state
    }
}