import React from "react";
import productScreenRu from "../../images/product-screen-ru.png";
import productScreenUk from "../../images/product-screen-uk.png";
import productScreenEn from "../../images/product-screen-en.png";
import getLanguage from "../../helpers/getLanguage";
import {updateField} from "../../store/actions";
import {useDispatch} from "react-redux";
import {initialScreenWrapper as translations} from "../../translations";

const productScreenSrc = {
    ru: productScreenRu,
    uk: productScreenUk,
    en: productScreenEn
}

export default () => {
    const dispatch = useDispatch()
    const language = getLanguage()
    const texts = translations[language]

    const languageChangeHandler = e => {
        localStorage.setItem('cbm_language', e.target.value)
        dispatch(updateField('lang', e.target.value))
    }

    return (
        <div className="initial-screen-decor-wrapper">
            <div className="language-wrapper">
                <select name="language" id="language" value={language} onChange={languageChangeHandler}>
                    <option value="ru">Русский</option>
                    <option value="en">English</option>
                    <option value="uk">Український</option>
                </select>
            </div>
            <img src={productScreenSrc[language]} alt="Product Screen" />
            <div className="initial-screen-decor-title">{texts.digitalAssistant}</div>
            <div className="initial-screen-decor-text">{texts.decorText}</div>
        </div>
    )
}