import React, {useEffect, lazy, Suspense} from "react"
import {useSelector, useDispatch} from "react-redux"
import {Route, Redirect, withRouter} from 'react-router-dom'
import './App.css'
import {getConsultations, getNotifications, restoreState, setScrollOffset, setWindowWidth} from "./store/actions"
import jsonParse from "./helpers/jsonParse"
import SignInPage from "./pages/SignInPage/SignInPage"
import SignUpPage from "./pages/SignUpPage/SignUpPage"
import SettingsPage from "./pages/SettingsPage/SettingsPage"
import MainHeader from "./components/MainHeader/MainHeader"
import Notification from "./components/Notification/Notification"
import PopupVerifyEmail from "./components/PopupVerifyEmail/PopupVerifyEmail";
import CreationAccount from "./pages/CreationAccount/CreationAccount";
import getLanguage from "./helpers/getLanguage";
import PatientPage from "./pages/PatientPage/PatientPage";
import Loader from "./components/Loader/Loader";
import SupportPage from "./pages/SupportPage/SupportPage";
const PatientsPage = lazy(() => import("./pages/MyPatientsPage/MyPatientsPage"))
const ConsultationsPage = lazy(() => import("./pages/ConsultationsPage/ConsultationsPage"))
const NotificationsPage = lazy(() => import("./pages/NotificationsPage/NotificationsPage"))


const App = props => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const appState = useSelector(state => state.app)
    const doctorData = appState.doctorData
    const language = appState.language || getLanguage()
    const isSetCookie = document.cookie.includes('cbm_login')
    const isAuth = appState.isAuth
    const menuOpened = appState.menuOpened
    const isShowNotification = appState.notification.show
    const notification = (isShowNotification && <Notification />) || null
    document.documentElement.lang = language
    window.cbm_prevYOffset = appState.pageOffset
    window.cbm_windowWidth = appState.windowWidth

    const updateNotifications = function () {
        if (isAuth) {
            dispatch(getConsultations(appState.doctorId))
            dispatch(getNotifications(appState.token, appState.doctorId))
        }
    }

    useEffect(() => {
        updateNotifications()

        dispatch(setWindowWidth(window.innerWidth))
        dispatch(setScrollOffset(window.pageYOffset))
        window.addEventListener('resize', () => {
            if ((window.cbm_windowWidth <= 1300 && window.innerWidth >= 1300) ||
                (window.cbm_windowWidth >= 1300 && window.innerWidth <= 1300)) {
                dispatch(setWindowWidth(window.innerWidth))
            }
        })
        window.addEventListener('scroll', () => {
            if ((window.cbm_prevYOffset <= 200 && window.pageYOffset >= 200) ||
                (window.cbm_prevYOffset >= 200 && window.pageYOffset <= 200)) {
                dispatch(setScrollOffset(window.pageYOffset))
            }
        })

        const previewLoader = document.getElementById('previewLoader')
        if (previewLoader) {
            if (Date.now() - window.loadTime > 1500) {
                previewLoader.classList.add('preview-loader--hiding')
            }
            setTimeout(function () {
                previewLoader.parentNode.removeChild(previewLoader)
            }, 500)
        }
        const root = document.getElementById('root')
        if (root) {
            root.classList.remove('root--hide')
        }
    }, [])
    useEffect(() => {
        window.cbm_notificationsInterval = setInterval(updateNotifications, 2 * 60 * 1000)
    }, [isAuth])

    /*
    * Если пользователь не авторизирован
    * */
    if (!isAuth) {
        /*
        * Пытаемся достать данные из localStorage и восстановить сеанс
        * */
        if (isSetCookie) {
            const data = localStorage.getItem('cbm')
            if (data) {
                const parsedData = jsonParse(data)
                if (parsedData) {
                    dispatch(restoreState(parsedData))
                    return <Loader />
                }
            }
        }

        /*
        * Если данных в localStorage нет и пользователь на странице логина,
        * то возвращается страница логина
        * */
        if (props.location.pathname === '/sign-in') {
            return <SignInPage />
        }

        /*
        * Если данных в localStorage нет и пользователь на странице регистрации,
        * то возвращается страница регистрации
        * */
        if (props.location.pathname === '/sign-up') {
            return <SignUpPage />
        }

        /*
        * Если данных в localStorage нет и пользователь на странице создания аккаунта,
        * то возвращается страница создания аккаунта
        * */
        if (props.location.pathname === '/creation-account' && localStorage.getItem('cbm_loginData')) {
            return <CreationAccount />
        }

        /*
        * Если данных в localStorage нет и пользователь на странице поддержки,
        * то возвращается страница поддержки
        * */
        if (props.location.pathname === '/support') {
            return <SupportPage />
        }

        /*
        * Если данных в localStorage нет и пользователь пытается попасть
        * на какую то страницу где требуется авторизация, то редирект на логин
        * */
        return <Redirect to="/sign-in" />
    }

    /*
    * Если пользователь авторизовался, но ещё не подтвердил почту
    * возвращаем попап с просьбой подтвердить
    * */
    if (appState.doctorData.is_active === false) {
        return <PopupVerifyEmail />
    }

    /*
    * Начинаем сохранять состояние с этого момента
    * */
    localStorage.setItem('cbm', JSON.stringify(state))

    /*
    * Если пользователь авторизовался, и у него не заполнены обязательные данные
    * переход на страницу создания доктора
    * */
    const creationAccountCondition = !doctorData.first_name ||
        !doctorData.last_name ||
        //!doctorData.specialization ||
        //!doctorData.position ||
        //!doctorData.experience ||
        //!doctorData.jobs ||
        //!doctorData.messenger ||
        !doctorData.phone_number

    if (creationAccountCondition) {
        if (props.location.pathname === '/creation-account') {
            return <CreationAccount />
        }

        return <Redirect to="/creation-account" />
    }

/*    if (window.navigator.onLine === false) {
        return <div>No internet</div>
    }*/
    return (
        <div className={`main-wrapper ${menuOpened ? 'main-wrapper--menu-opened' : ''}`}>
            <MainHeader />
            <main className="main-layout">
                <Suspense fallback={<Loader />}>
                    <Route path="/" exact render={()=> <Redirect to="/patients" />} />
                    <Route path="/sign-in" render={()=> <Redirect to="/patients" />} />
                    <Route path="/sign-up" render={()=> <Redirect to="/patients" />} />
                    <Route path="/creation-account" render={()=> <Redirect to="/patients" />} />
                    <Route path="/patients/:id" component={PatientPage} />
                    <Route path="/patients" exact component={PatientsPage} />
                    <Route path="/consultations" component={ConsultationsPage} />
                    <Route path="/notifications" component={NotificationsPage} />
                    <Route path="/settings" component={SettingsPage} />
                    <Route path="/support" component={SupportPage} />
                    <Route path="/404" render={()=> <h1>Page not found</h1>} />
                </Suspense>
            </main>
            {notification}
        </div>
    )
}

export default withRouter(App)
