import React, {useEffect} from "react"
import {NavLink, withRouter} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import PatientsSearch from "../../components/PatientsSearch/PatientsSearch"
import './PatientPage.css'
import PatientCard from "../../components/PatientCard/PatientCard";
import {
    updatePatientData,
    setInitialPatientState,
    updatePatientField,
    pushDayData
} from "../../store/actions";
import PatientRehabilitationProgram from "../../components/PatientRehabilitationProgram/PatientRehabilitationProgram";
import PatientSteps from "../../components/PatientSteps/PatientSteps";
import ChangeCurrentLoad from "../../components/ChangeCurrentLoad/ChangeCurrentLoad";
import {patientPage as translations} from "../../translations";
import _ from "../../helpers/_";
import jsonParse from "../../helpers/jsonParse";
import {API_BASE_URL} from "../../constants/appConstants";
import '../MyPatientsPage/MyPatientsPage.css'
import Loader from "../../components/Loader/Loader";


const getWeeksCount = (week, prevWeek = 0) => {
    if (week - prevWeek === 1) {
        return week
    }
    if (week - prevWeek === 2) {
        return prevWeek + 1 + ',' + week
    }
    return prevWeek + 1 + '-' + week
}
const getStageStatus = (start, end) => {
    const nowDate = new Date()
    const startDate = new Date(start)
    const endDate = new Date (end)
    if (nowDate > endDate) {
        return 'over'
    } else if (nowDate > startDate && nowDate < endDate) {
        return 'current'
    }
    return 'next'
}
const addDataToPatientStages = (stages, texts) => {
    let weeksCountAccumulator = 0
    return stages.map(stage => {
        stage.pressureText = stage.start_pressure === stage.end_pressure
            ? stage.start_pressure + '%'
            : `${texts.from} ${stage.start_pressure}% ${texts.to} ${stage.end_pressure}%`

        const newWeeksCountAccumulator = weeksCountAccumulator + +stage.week_count
        const weeks = getWeeksCount(newWeeksCountAccumulator, weeksCountAccumulator)
        weeksCountAccumulator = newWeeksCountAccumulator
        stage.weeksText = weeks + ' ' + texts.week

        stage.status = getStageStatus(stage.start_date, stage.end_date)

        return stage
    })
}
const prepareDateData = days => {
    const getEmptyDay = date => {
        const thisDate = new Date(date)
        return {
            statistics: {
                low_load_steps_count: 0,
                normal_steps_count: 0,
                high_load_steps_count: 0,
            },
            date: thisDate,
            status: 'empty',
        }
    }
    const daysInMonth =  (month, year) => {
        return new Date(year, +month + 1, 0).getDate();
    }

    let weeks = []
    let months = []

    for (let i = 0; i < days.length; i++) {
        const day = days[i]
        const date = new Date()
        date.setTime(+day.timestamp * 1000)
        day.date = date

        const month = date.getMonth()

        const weekKey = Math.trunc(i / 7)
        if (!weeks[weekKey]) {
            weeks[weekKey] = []
        }

        weeks[weekKey].push(day)

        /*
        * Заполняем недостающие дни в неделе пустыми данными
        * */
        if (i === days.length - 1 && weeks[weekKey].length !== 7) {
            const weeksDate = new Date()
            weeksDate.setTime(+day.timestamp * 1000)
            let prevWeeksDate = weeksDate
            for (let k = 0; weeks[weekKey].length < 7; k++) {
                prevWeeksDate.setDate(prevWeeksDate.getDate() + 1)
                weeks[weekKey].push(getEmptyDay(prevWeeksDate))
            }
        }

        if (!months[month]) {
            months[month] = []
            /*
            * Заполняем недостающие дни в начале месяца пустыми данными
            * */
            if (date.getDate() !== 1) {
                const forMonths = new Date(date)
                forMonths.setDate(1)
                for (let k = 1; k < date.getDate(); k++) {
                    months[month].push(getEmptyDay(forMonths))
                    forMonths.setDate(forMonths.getDate() + 1)
                }
            }
        }

        months[month].push(day)

        /*
        * Заполняем недостающие дни в конце месяца пустыми данными
        * */
        if (i === days.length - 1) {
            const daysInThisMonth = daysInMonth(month, date.getFullYear())
            if (date.getDate() !== daysInThisMonth) {
                const forMonths = new Date(date)
                for (let k = forMonths.getDate(); k < daysInThisMonth; k++) {
                    forMonths.setDate(forMonths.getDate() + 1)
                    months[month].push(getEmptyDay(forMonths))
                }
            }

            /*
            * Убираем пустые ячейки массива
            * */
            months = months.filter(Boolean)
        }
    }
    return {days, weeks, months}
}
window.eventSource = null;
export default withRouter(props => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const token = useSelector(state => state.app.token)
    const loaded = useSelector(state => state.patient.loaded)
    const daysLoaded = useSelector(state => state.patient.loaded)
    const dataPrepared = useSelector(state => state.patient.loaded)
    const daysData = useSelector(state => state.patient.daysData)
    const popup = useSelector(state => state.patient.popup)
    const patientData = useSelector(state => state.patient.patientData)
    const texts = translations[language]
    const patientId = props.match.params.id
    const currentProgram = patientData.current_program || {}
    //const currentProgram = patientData
    const currentLoad = currentProgram ? +currentProgram.current_load : 0
    window.currentLoad = currentLoad;

    const onmessageCallback = e => {
        const data = jsonParse(e.data)
        const patientWeight = +patientData.weight
        const onePercentWeight = 100 / patientWeight
        const weightLeft = +data.weight_left
        const weightRight = +data.weight_right
        let stepLoad = +(100 - (weightLeft * onePercentWeight + weightRight * onePercentWeight)).toFixed(0)
        // это костыль для отображения нагрузки на графике при значении меньше 2
        stepLoad = stepLoad <= 2 ? Math.max(stepLoad, 0) + 1 : stepLoad
        const maxLoad = window.currentLoad + 10
        const minLoad = window.currentLoad > 10 ? window.currentLoad - 10 : window.currentLoad

        let stepType = ''
        if (stepLoad >= minLoad && stepLoad <= maxLoad) {
            stepType = 'normal'
        } else if (stepLoad > maxLoad) {
            stepType = 'high'
        } else {
            stepType = 'low'
        }

        const timestamp = data.timestamp

        const payload = {stepType, stepLoad, timestamp}

        dispatch(pushDayData(payload))
    }

    const stepTypeUpdate = (data, currentLoad) => {
        const maxLoad = currentLoad + 10
        const minLoad = currentLoad > 10 ? currentLoad - 10 : currentLoad

        const newData = data.map(step => {
            if (step.stepLoad < minLoad) {
                step.stepType = 'low'
            } else if (step.stepLoad <= maxLoad ) {
                step.stepType = 'normal'
            } else {
                step.stepType = 'high'
            }
            return step
        });

        dispatch(updatePatientField('daysData', newData))
    }

    useEffect(() => {
        dispatch(setInitialPatientState())
        dispatch(updatePatientData(patientId))
        return () => {
            window.eventSource && window.eventSource.close();
            dispatch(setInitialPatientState())
        }
    }, [])

    useEffect(() => {
        if (loaded === true) {
            stepTypeUpdate(daysData, currentLoad)
        }
    }, [currentLoad, loaded])

    useEffect(() => {
        if (loaded === true) {
            const startDate = new Date(new Date().setUTCHours(0,0,0)).toISOString()
            window.eventSource = new EventSource(`${API_BASE_URL}/steps/${patientId}?token=${token}&start_date=${startDate}`);
            window.eventSource.onmessage = onmessageCallback
        }
    }, [loaded])

    useEffect(() => {
        let patientStages = currentProgram && currentProgram.stages ? currentProgram.stages : []
        const patientStagesLength = patientStages.length

        /*
        *
        * */
        if (patientStagesLength) {
            patientStages = addDataToPatientStages(patientStages, texts)

            /*
            * Сохраняем patientStages для программы восстановления
            * */
            dispatch(updatePatientField('patientStages', patientStages))
        }

        if (currentProgram && currentProgram.days && currentProgram.days.length) {

            /*
            * Сохраняем weeksData, monthsData для графиков о шагах и нагрузке
            * */
            let {days, weeks, months} = prepareDateData(currentProgram.days)
            dispatch(updatePatientField('allData', days))
            dispatch(updatePatientField('weeksData', weeks))
            dispatch(updatePatientField('monthsData', months))

            /*
            * Сохраняем weeksControlTexts для переключателя недель
            * */
            const weeksControlTexts = weeks.map((week, i) => {
                const firstDay = week[0].date.getDate()
                const lastDay = week[6].date.getDate()

                const firstDayMonth = week[0].date.toLocaleString(language, { month: 'short' }).split('.')[0].toLowerCase()
                const lastDayMonth = week[6].date.toLocaleString(language, { month: 'short' }).split('.')[0].toLowerCase()
                const firstDayMonthCondition = firstDayMonth !== lastDayMonth ? firstDayMonth + '.' : ''

                const firstDayYear = week[0].date.getFullYear()
                const lastDayYear = week[6].date.getFullYear()
                const firstDayYearCondition = firstDayYear !== lastDayYear ? ` ${firstDayYear}${texts.yearShort}.` : ''

                const text = texts.week.charAt(0).toUpperCase() + texts.week.slice(1)

                return {
                    text: `${text} ${i + 1}`,
                    dateText: `(${firstDay} ${firstDayMonthCondition} ${firstDayYearCondition} - ${lastDay} ${lastDayMonth}. ${lastDayYear}${texts.yearShort}.)`
                }
            })
            dispatch(updatePatientField('weeksControlTexts', weeksControlTexts))

            /*
            * Сохраняем monthsControlTexts для переключателя месяцев
            * */
            const monthsControlTexts = months.map((month, i) => {
                let monthText = month[0].date.toLocaleString(language, { month: 'long' }).split('.')[0]
                monthText = monthText.charAt(0).toUpperCase() + monthText.slice(1)

                const year = month[0].date.getFullYear()

                return {
                    text: `${texts.month} ${i + 1}`,
                    dateText: `(${monthText} ${year}${texts.yearShort}.)`
                }
            })
            dispatch(updatePatientField('monthsControlTexts', monthsControlTexts))

            /*
            * Сохраняем allControlTexts для отображения всего периода
            * */
            days = days.filter(day => day.status !== 'empty')
            const firstDay = days[0].date
            const firstDayDate = firstDay.getDate()
            const firstDayMonth = firstDay.toLocaleString(language, { month: 'short' }).split('.')[0].toLowerCase()
            const lastDay = days[days.length - 1].date
            const lastDayDate = lastDay.getDate()
            const lastDayMonth = lastDay.toLocaleString(language, { month: 'short' }).split('.')[0].toLowerCase()
            const lastDayYear= lastDay.getFullYear()
            const allControlTexts = [{
                text: `${texts.allPeriod}`,
                dateText: `(${firstDayDate} ${firstDayMonth}. - ${lastDayDate} ${lastDayMonth}. ${lastDayYear}${texts.yearShort}.)`
            }]
            dispatch(updatePatientField('allControlTexts', allControlTexts))
        }

        /*
        * Сохраняем patientData для карты пациента
        * */
        dispatch(updatePatientField('patientData', patientData))

        dispatch(updatePatientField('dataPrepared', true))


    //}, [patient.id/*, currentLoad*/])
    }, [loaded, daysLoaded, currentLoad])

    /*
    * Returns
    * */
    //if (loaded === false || daysLoaded === false || dataPrepared === false) {
    //if (loaded === false || dataPrepared === false) {
    if (dataPrepared === false) {
        return <Loader />
    }

    /*
    * Data
    * */
    const patientFullName = patientData.first_name && patientData.last_name
        ? patientData.first_name + ' ' + patientData.last_name
        : patientData.phone_number

    /*
    * Components
    * */
    const popupContent = _(popup === 'change_current_load', <ChangeCurrentLoad />)

    return (
        <>
            <div className="patient-page">

                {/*header*/}
                <header className="page-header">
                    <PatientsSearch />
                    <div className="bread-crumbs-wrapper">
                        <NavLink to="/patients" className="bread-crumbs-link" >{texts.myPatients}</NavLink>
                        <span className="bread-crumbs-more">&gt;</span>
                        <span>{patientFullName}</span>
                    </div>
                </header>

                {/*content*/}
                <div className="page-content-wrapper">
                    <div className="page-content-inner-wrapper">

                        {/*patient card*/}
                        <PatientCard />

                        {/*rehabilitation program*/}
                        {_(currentProgram && dataPrepared,
                        <PatientRehabilitationProgram/>)}

                        {/*steps and load*/}
                        {_(currentProgram.days && currentProgram.days.length,
                        <PatientSteps/>)}
                    </div>
                </div>
            </div>
            {popupContent}
        </>
    )
})