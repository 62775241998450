import React, {useEffect, createRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {
    saveNewPassword,
    setActiveTab,
    setSettingData,
    setSettingTabData,
    showNotification, showFieldsErrors,
    updateField,
    updateFieldValidation
} from "../../store/actions"
import './ChangePassword.css'
import waveEffect from "../../helpers/waveEffect";
import ValidateField from "../ValidateField/ValidateField";
import {changePassword as translations} from "../../translations";


export default () => {
    const tabKey = 'changePassword'
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const token = useSelector(state => state.app.token)
    const doctorId = useSelector(state => state.app.doctorId)
    const doctorPassword = useSelector(state => state.app.password)
    const tabData = useSelector(state => state.settings.temp[tabKey])
    const validation = useSelector(state => state.validation.validation)
    const [newPassLength, changeNewPassLength] = useState((tabData.newPassword.length > 7 && tabData.newPassword.length < 25))
    const [newPassSpecific, changeNewPassSpecific] = useState(/(?=.*[\d])(?=.*[A-Za-z])/.test(tabData.newPassword))
    const texts = translations[language]
    const currentPasswordRef = createRef()
    const newPasswordRef = createRef()
    const confirmPasswordRef = createRef()
    const [passwordCapsLock, changePasswordCapsLock] = useState(false)
    const [newPasswordCapsLock, changeNewPasswordCapsLock] = useState(false)
    const [confirmPasswordCapsLock, changeConfirmPasswordCapsLock] = useState(false)

    useEffect(() => () => dispatch(setSettingTabData(tabKey)), [])

    const toggleType = ref => () => {
        ref.current.type === 'text'
            ? (ref.current.type = 'password')
            : (ref.current.type = 'text')
    }
    const fieldBlurHandler = fieldKey => () => {
        const enableError = validation.hasOwnProperty(fieldKey) && !validation[fieldKey].showError
        if (enableError) {
            dispatch(updateFieldValidation({id: fieldKey, showError: true}))
        }
    }
    const fieldChangeHandler = fieldKey => ({target: { value }}) => dispatch(setSettingData(tabKey, fieldKey, value))
    const newPasswordChangeHandler = fieldKey => ({target: {value}}) => {
        if (value.length > 7 && value.length < 25) {
            changeNewPassLength(true)
        } else {
            changeNewPassLength(false)
        }
        if (/(?=.*[\d])(?=.*[A-Za-z])/.test(value)) {
            changeNewPassSpecific(true)
        } else {
            changeNewPassSpecific(false)
        }
        dispatch(setSettingData(tabKey, fieldKey, value))
    }
    const saveButtonHandler = e => {
        waveEffect(e)
        const tabInvalid = Object.values(validation).find(item => item.valid === false)
        if (tabInvalid) {
            dispatch(showFieldsErrors())
        } else {
            dispatch(saveNewPassword(token, doctorId, tabData.newPassword))
            dispatch(updateField('password', tabData.newPassword))
            dispatch(setActiveTab(''))
            dispatch(showNotification('', texts.changesSaved))
        }
    }
    const keyDownHandler = (state, stateHandler) => e => {
        if (e.getModifierState('CapsLock') && !state) {
            stateHandler(true)
        } else if (!e.getModifierState('CapsLock') && state) {
            stateHandler(false)
        }
    }

    const currentPasswordValidationRules = [[tabData.currentPassword !== doctorPassword, texts.invalidPassword]]
    const currentPasswordShowError = (validation && validation.currentPassword && validation.currentPassword.showError) || false

    const newPasswordValidationRules = [
        [tabData.newPassword === doctorPassword, texts.matchPass],
        [!(tabData.newPassword.length > 7 && tabData.newPassword.length < 25), ''],
        [!/(?=.*[\d])(?=.*[A-Za-z])/.test(tabData.newPassword), '']
    ]
    const newPasswordShowError = (validation && validation.newPassword && validation.newPassword.showError) || false

    const confirmPasswordValidationRules = [
        [tabData.confirmPassword !== tabData.newPassword, texts.notMatchPass],
        [tabData.confirmPassword === '', ''],
    ]
    const confirmPasswordShowError = (validation && validation.confirmPassword && validation.confirmPassword.showError) || false


    return (
        <>
            <div className="tab-section">

                {/*current password*/}
                <label htmlFor="currentPassword" className="tab-label password">
                    <div className="tab-label-text">
                        {texts.currentPassword}
                    </div>
                    <ValidateField id="currentPassword" validation={currentPasswordValidationRules} showError={currentPasswordShowError}>
                        <input type="password" value={tabData.currentPassword} className="tab-input"
                               placeholder={texts.currentPasswordPlaceholder}
                               ref={currentPasswordRef}
                               onKeyDown={keyDownHandler(passwordCapsLock, changePasswordCapsLock)}
                               onChange={fieldChangeHandler('currentPassword')}
                               onBlur={fieldBlurHandler('currentPassword')} />
                        <div className="pass-type-toggle" onClick={toggleType(currentPasswordRef)} />
                        {passwordCapsLock && <div className="caps-lock">{texts.capsLock}</div>}
                    </ValidateField>
                </label>

                {/*new password*/}
                <label htmlFor="newPassword" className="tab-label password">
                    <div className="tab-label-text">
                        {texts.newPassword}
                    </div>
                    <ValidateField id="newPassword" validation={newPasswordValidationRules} showError={newPasswordShowError}>
                        <input type="password" value={tabData.newPassword} className="tab-input"
                               placeholder={texts.newPasswordPlaceholder}
                               ref={newPasswordRef}
                               onKeyDown={keyDownHandler(newPasswordCapsLock, changeNewPasswordCapsLock)}
                               onChange={newPasswordChangeHandler('newPassword')}
                               onBlur={fieldBlurHandler('newPassword')} />
                        <div className="pass-type-toggle" onClick={toggleType(newPasswordRef)} />
                        {newPasswordCapsLock && <div className="caps-lock">{texts.capsLock}</div>}
                    </ValidateField>
                    <div className="new-password-hints-wrapper">
                        <span className={`new-password-hint ${newPassLength ? 'new-password-hint--active' : ''}`}>
                            {texts.passLengthHint}
                        </span>
                        <span className={`new-password-hint ${newPassSpecific ? 'new-password-hint--active' : ''}`}>
                            {texts.passRuleHint}
                        </span>
                    </div>
                </label>

                {/*confirm password*/}
                <label htmlFor="confirmPassword" className="tab-label password">
                    <div className="tab-label-text">
                        {texts.confirmPassword}
                    </div>
                    <ValidateField id="confirmPassword" validation={confirmPasswordValidationRules} showError={confirmPasswordShowError}>
                        <input type="password" value={tabData.confirmPassword} className="tab-input"
                               placeholder={texts.confirmPasswordPlaceholder}
                               ref={confirmPasswordRef}
                               onKeyDown={keyDownHandler(confirmPasswordCapsLock, changeConfirmPasswordCapsLock)}
                               onChange={fieldChangeHandler('confirmPassword')}
                               onBlur={fieldBlurHandler('confirmPassword')} />
                        <div className="pass-type-toggle" onClick={toggleType(confirmPasswordRef)} />
                        {confirmPasswordCapsLock && <div className="caps-lock">{texts.capsLock}</div>}
                    </ValidateField>
                </label>
            </div>

            <div className="tab-section">
                <button className="tab-button tab-button--save" onClick={saveButtonHandler}>
                    {translations[language].save}
                </button>
            </div>
        </>
    )
}