import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import waveEffect from "../../helpers/waveEffect"
import {
    setActiveTab,
    setSettingData,
    setSettingTabData, showNotification,
    showFieldsErrors,
    updateContacts, updateFieldValidation
} from "../../store/actions"
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import './ChangeContacts.css'
import {ReactComponent as TelegramIcon} from '../../images/telegram-icon.svg'
import {ReactComponent as MailIcon} from '../../images/mail-icon.svg'
import ValidateField from "../ValidateField/ValidateField";
import {changeContacts as translations} from "../../translations";


export default () => {
    const tabKey = 'changeContacts'
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const token = useSelector(state => state.app.token)
    const doctorId = useSelector(state => state.app.doctorId)
    const tabData = useSelector(state => state.settings.temp[tabKey])
    const messenger = useSelector(state => state.app.doctorData.messenger)
    let phone = useSelector(state => state.app.doctorData.phone_number)
    const validation = useSelector(state => state.validation.validation)
    const texts = translations[language]

    if (!/^\+/.test(phone) && phone !== '') {
        phone = '+' + phone
    }

    useEffect(() => {
        const data = { messenger, phone }
        dispatch(setSettingTabData(tabKey, data))
    }, [phone, messenger])

    const moveCursorPositionHandler = phone => {
        setTimeout(() => {
            const element = document.getElementById('phoneNumber')
            if (element) {
                const cursorPosition = phone.length
                element.setSelectionRange(cursorPosition, cursorPosition)
            }
        }, 40)
    }
    const phoneChangeHandler = (isValid, phone, country) => {
        phone = phone.replace(/ /g, '')

        if (/^\+$|^\+\d+$/.test(phone) || phone === '') {
            dispatch(updateFieldValidation({id: 'phone', valid: isValid}))
            dispatch(setSettingData(tabKey, 'phone', phone))
        }
    }
    const phoneBlurHandler = () => dispatch(updateFieldValidation({id: 'phone', showError: true}))
    const radioClickHandler = value => () => {
        dispatch(setSettingData(tabKey, 'messenger', value))
    }
    const saveButtonHandler = e => {
        waveEffect(e)
        const tabInvalid = Object.values(validation).find(item => item.valid === false)
        if (tabInvalid) {
            dispatch(showFieldsErrors())
        } else {
            dispatch(updateContacts(token, doctorId, {messenger: tabData.messenger, phone_number: tabData.phone}))
            dispatch(showNotification('', texts.changesSaved))
            dispatch(setActiveTab(''))
        }
    }

    const phoneValidationRules = [[!(validation && validation.phone && validation.phone.valid), texts.enterValidPhone]]
    const phoneShowError = (validation && validation.phone && validation.phone.showError) || false

    if (tabData.loaded === false) return <div />
    return (
        <>
            <div className="tab-section">
                <div className="tab-label">
                    <div className="tab-label-text">
                        {texts.phoneNumber}
                    </div>
                    <ValidateField id="phone" showError={phoneShowError} validation={phoneValidationRules}>
                        <IntlTelInput autoHideDialCode={false} nationalMode={false} preferredCountries={['ua', 'us', 'ru']}
                                      containerClassName="intl-tel-input"
                                      inputClassName="tab-input"
                                      fieldId="phoneNumber"
                                      onPhoneNumberChange={phoneChangeHandler}
                                      onPhoneNumberBlur={phoneBlurHandler}
                                      onSelectFlag={moveCursorPositionHandler}
                                      value={tabData.phone}
                        />
                    </ValidateField>
                </div>

            </div>

            <div className="tab-section">
                <div className="prefer-title">
                    {texts.preferContact}
                </div>
                <button className={`radio-button ${tabData.messenger === 'telegram' ? 'radio-button--active' : '' }`}
                        onClick={radioClickHandler('telegram')} >
                    <div className="radio-button-img-wrapper telegram">
                        <TelegramIcon />
                    </div>
                    <div className="radio-button-text">Telegram</div>
                    <div className="radio-button-checkmark" />
                </button>
                <button className={`radio-button ${tabData.messenger === 'sms' ? 'radio-button--active' : '' }`}
                        onClick={radioClickHandler('sms')} >
                    <div className="radio-button-img-wrapper mail">
                        <MailIcon />
                    </div>
                    <div className="radio-button-text">SMS</div>
                    <div className="radio-button-checkmark" />
                </button>
            </div>

            <div className="tab-section">
                <button className="tab-button tab-button--save" onClick={saveButtonHandler}>
                    {texts.save}
                </button>
            </div>
        </>
    )
}